import { Box, CircularProgress, styled, Typography } from '@mui/material';
import { uploadFile } from 'cloudinary';
import { Button } from 'components/Ui-V2/Button/Button';
import { pick } from 'lodash';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FieldError } from 'react-hook-form';
import { EColors, getColor } from 'theme';

const Container = styled(Box, {
    shouldForwardProp: prop => prop !== 'errror',
})<{ errror: boolean }>(({ theme: { getColor, EColors }, error }) => ({
    boxSizing: 'border-box',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '50px',
    fontSize: '1rem',
    border: '1px solid',
    borderColor: error ? getColor(EColors.red) : getColor(EColors.inactiveAction),
    borderRadius: '4px',
    padding: '10px',
    color: getColor(EColors.pureBlack),
}));

type ImageInputProps = {
    label: string;
    placeholder?: string;
    error: FieldError | undefined;
    onChange: (url: string) => void;
    onBlur?: () => void;
    value: string | undefined;
    defaultValue?: string;
    disabled?: boolean | undefined;
    required?: boolean;
    ref?: React.LegacyRef<HTMLInputElement> | undefined;
};

const ImageInput = ({
    label,
    error,
    placeholder,
    required = false,
    value: image,
    onChange,
    defaultValue,
    disabled,
}: ImageInputProps) => {
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const onDrop = useCallback(
        async (acceptedFiles: File[]) => {
            try {
                setIsUploading(true);
                const cloudinaryResponse = await uploadFile(acceptedFiles[0]);
                const imgObject = pick(cloudinaryResponse, ['cloudinaryId', 'title', 'url']);
                onChange(imgObject.url);
            } finally {
                setIsUploading(false);
            }
        },
        [onChange]
    );

    const { getRootProps, getInputProps, open } = useDropzone({
        onDrop,
        accept: {
            'image/png': ['.png'],
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/webp': ['.webp'],
        },
        noClick: true,
        maxSize: 1024 * 1024 * 5, //5mb
    });

    return (
        <Box>
            <Box display="flex" gap={0.5} marginBottom={1}>
                <Typography fontWeight={500}>{label}</Typography>

                <Typography color={getColor(EColors.pureBlack, 0.5)} fontWeight={500}>
                    {required ? '(required)' : ''}
                </Typography>
            </Box>
            <Container {...getRootProps()}>
                <input {...getInputProps()} />
                {isUploading ? (
                    <CircularProgress size={14} sx={{ color: getColor(EColors.bizlyOSPrimary) }} />
                ) : image ? (
                    <img width={30} height={30} style={{ borderRadius: '4px', objectFit: 'contain' }} src={image} />
                ) : defaultValue ? (
                    <img
                        width={30}
                        height={30}
                        style={{ borderRadius: '4px', objectFit: 'contain' }}
                        src={defaultValue}
                    />
                ) : (
                    <Typography variant="body2" color={getColor(EColors.pureBlack, 0.5)}>
                        {placeholder}
                    </Typography>
                )}

                <Button variant="text" sx={{ height: '30px' }} disabled={disabled} onClick={open}>
                    Change
                </Button>
            </Container>
            {!!error && (
                <Typography variant="body2" color={getColor(EColors.red)} fontWeight={500}>
                    {error.message}
                </Typography>
            )}
        </Box>
    );
};

export default ImageInput;
