import Image from 'components/Ui-V2/Image/Image';
import DashboardVenueTile from 'components/VenueTile/DashboardVenueTile';
import VenueTilePlaceHolder from 'images/placeholders/venue-tile-placeholder.png';
import { padArray } from '../../../util';
import { DEFAULT, NO_CITY, TBookedStatus } from './utils';
export const VenueTiles = ({
    venues,
    bookedState,
    viewVenueListing,
}: {
    venues: Bizly.Venue[];
    bookedState: TBookedStatus;
    viewVenueListing?: (venueId: number) => void;
    placeholderBackground?: Themed.Color;
}) => (
    <>
        {padArray(venues, 4)
            .slice(0, 4)
            .map((venue: Bizly.Venue | undefined, idx) =>
                venue ? (
                    <DashboardVenueTile
                        key={venue.id + idx}
                        venue={venue}
                        format="tile"
                        pillType={
                            bookedState === DEFAULT
                                ? 'preferenceCategory'
                                : bookedState !== NO_CITY
                                  ? 'inquiryStatus'
                                  : undefined
                        }
                        fixedRatio="63%"
                        onClick={viewVenueListing}
                    />
                ) : (
                    <Image src={VenueTilePlaceHolder} alt="No added venues place holder" height="100%" width="100%" />
                )
            )}
    </>
);
