import { Tooltip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { BizlyThemeOverride } from 'ThemedApp';
import { getPlaybookParcels, updatePlaybookParcel } from 'api/playbooks';
import { CloudinaryUploader } from 'components/CloudinaryUploader';
import ConfirmationModal from 'components/ConfirmationModal';
import LogoEditor from 'components/LogoEditor';
import { MEETINGS_NAV_WIDTH } from 'components/MeetingsNav';
import ScheduleFlyout, { TScheduleForm } from 'components/Parcel/Schedule';
import { SIDE_NAV_WIDTH, SIDE_SMALL_NAV_WIDTH } from 'components/SideNav';
import WebRegistrationModal from 'components/WebRegistration/WebRegistrationModal';
import TextButton from 'components/ui/Button/TextButton';
import HoverableIcon from 'components/ui/HoverableIcon';
import { isAfter, isSameDay, parseISO } from 'date-fns';
import useShowModal from 'hooks/useShowModal';
import useUnsavedPrompt from 'hooks/useUnsavedPrompt';
import useUploadLogo from 'hooks/useUploadLogo';
import { capitalize, isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useEvent } from 'providers/event';
import { useUser } from 'providers/user';
import React, { useEffect, useMemo } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { i18n } from 'translation';
import { AlignedRow, Button, Column, InlineRow, Row } from 'ui';
import { getDateTimeStrings } from 'utils/date_util';
import {
    copyParcel,
    deleteParcel,
    generateInviteSubjectAndBody,
    getEventPlannerData,
    getPlaybookPlannerData,
    loadAttendees,
    loadParcel,
    publishParcel,
    sendParcel,
    updateParcel,
} from '../../api';
import { Spinner, SpinnerOverlay } from '../../components/Spinner';
import { StickyHeader } from '../../components/StickyHeader';
import AIIcon from '../../images/icons/ai.svg?react';
import EditParcelPreview from './EditParcelPreview';
import { EventRegistrationPage, NotePage, PrivateInvite, SurveyPage } from './ParcelPages';
import HotelConfirmationEmail from './ParcelPages/HotelConfirmationEmail';
import { HOTEL_CONF, INVITE, NOTE, REGISTRATION_PAGE, SURVEY, copyRegistrationSlugToClipboard } from './utils';

const CUSTOM_LOGO = 'customLogo';
const CUSTOM_LOGO_URL = 'customLogoUrl';

const LogoActionButtons = styled.div`
    display: flex;
`;

const LogoButtonsRow = styled(Row)`
    justify-content: space-between;
    margin: 0 0;
    align-items: center;
    width: 100%;
    min-height: 2.5rem;
`;

const AIButton = styled(Button)`
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.blushPink)};
    border-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.fuchsiaPink)};
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureBlack)};
    margin-right: 1rem;

    &:hover {
        background: ${({ theme: { getColor, EColors } }) => getColor(EColors.fuchsiaPink)};
        border-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.fuchsiaPink)};
    }

    &:hover .MuiButton-label {
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    }
`;

const ActionButton = styled(Button)`
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    border-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
    margin-right: 1rem;

    &:hover,
    &:focus {
        background: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
        border-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    }
`;

const SendButton = styled(Button)`
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
`;

const SendOptionButton = styled(Button)`
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;

    &.MuiButton-root {
        padding-right: 0;
        padding-left: 0;
        min-width: auto;
    }

    .MuiButton-label {
        border-left: 1px solid;
    }
`;

export type TEventParcelContext = Omit<Partial<Bizly.Event>, 'id'> & {
    id: string | number;
    asPlaybookParcel?: boolean;
    meeting?: Partial<{
        startsAt: string;
        endsAt: string;
        timeZone: string;
    }>;
};

const isSendable = (parcel: Bizly.Parcel, parcelType: Bizly.ParcelType) => {
    const { subject, content, recipients, startDate, startTime, endDate, endTime } = parcel;
    const validDatesAndTimes =
        parcelType === INVITE || parcelType === REGISTRATION_PAGE ? startDate && startTime && endDate && endTime : true;

    // Recipients are not required for public registration invites
    if (parcelType === REGISTRATION_PAGE) {
        return subject && content && recipients && subject.length > 0 && content.length > 0 && validDatesAndTimes;
    }

    // Recipients are not required for public registration invites
    if (parcelType === HOTEL_CONF) {
        return (
            subject &&
            content &&
            subject.length > 0 &&
            content.length > 0 &&
            recipients &&
            recipients.length > 0 &&
            !parcel.sent
        );
    }

    return (
        subject &&
        content &&
        recipients &&
        subject.length > 0 &&
        content.length > 0 &&
        recipients.length > 0 &&
        validDatesAndTimes
    );
};

export type ParcelFormState = Omit<Bizly.Parcel, 'rsvpDueAt'> & {
    rsvpDueDate?: string;
    rsvpDueTime?: string;
    scheduleDate?: string;
    scheduleTime?: string;
    customImageUrl?: string;
    customLogoUrl?: string;
    postCutoffContact?: string;
};

export type EditParcelState = {
    customImage: unknown;
    pending: boolean;
    parcel: ParcelFormState;
    recipients: number[];
    showPreview: boolean;
    attendees: BizlyAPI.Attendee[];
    event: unknown;
    plannerSchedule: BizlyAPI.ScheduleBlock[];
};

export default function EditParcel({
    context,
    onParcelSave,
    onParcelSend,
}: {
    context: TEventParcelContext;
    onParcelSave?: (parcel: Bizly.Parcel) => void;
    onParcelSend?: (attendeesCounts: Bizly.AttendeeCounts) => void;
}) {
    const { user } = useUser();
    const { event } = useEvent();

    const navigate = useNavigate();

    const { type: typeParam, parcelId: parcelIdParam } = useParams<{ type: string; parcelId: string }>();
    const type = typeParam as Bizly.ParcelType;

    const [searchParams, setSearchParams] = useSearchParams();

    const parcelId = parcelIdParam ? Number(parcelIdParam) : undefined;
    const parcelIdRef = React.useRef();
    const schedulerRef = React.useRef<HTMLDivElement>(null);

    const { enqueueSnackbar } = useSnackbar();

    const initialLoad = React.useRef(false);

    const [state, setState] = React.useState<EditParcelState>({
        customImage: null,
        pending: true,
        parcel: {},
        recipients: [],
        showPreview: false,
        attendees: [],
        event: null,
        plannerSchedule: [],
    });

    const [loadingAIContent, setLoadingAIContent] = React.useState(false);
    const [scheduleAnchor, setScheduleAnchor] = React.useState<Nullable<HTMLElement>>(null);

    const [hasQuestions, setHasQuestions] = React.useState<boolean>(false);

    const { modalShown, showModal, hideModal } = useShowModal();

    const {
        handleLogoUpload,
        showLogoEditor,
        setShowLogoEditor,
        logo,
        setLogo,
        isLoading,
        setRotation,
        rotation,
        setCroppedAreaPixels,
    } = useUploadLogo();

    const webRegistrationRedesignFlagEnabled = useFeatureFlagEnabled('AiWebRegistrationRedesign');

    const [unsavedForm, setUnsavedForm] = React.useState(false);
    const [redirectUrl, setRedirectUrl] = React.useState('');

    const unsavedPrompt = useUnsavedPrompt(unsavedForm);

    useEffect(() => {
        initialLoad.current = false;
    }, [parcelId]);

    useEffect(() => {
        async function load() {
            const eventId = context.id;
            const getParcel = context.asPlaybookParcel
                ? (playbookId: number | string, parcelId: number | string) =>
                      getPlaybookParcels(playbookId).then(({ parcels }) => parcels.find(p => p.id === parcelId))
                : loadParcel;
            const getPlannerData = context.asPlaybookParcel ? getPlaybookPlannerData : getEventPlannerData;

            initialLoad.current = true;

            setState({
                ...state,
                pending: true,
            });

            let parcel: ParcelFormState | null = null,
                attendees: Bizly.EventAttendee[] = [],
                recipients: number[] = [],
                plannerSchedule: BizlyAPI.ScheduleBlock[] = [];

            try {
                if (!context.asPlaybookParcel) {
                    const response = await loadAttendees(eventId);
                    attendees = response?.attendees || [];
                    recipients = attendees.map(({ id }: { id: number }) => id);
                }
                plannerSchedule = (await getPlannerData(eventId)).planner.schedule || [];
                if (parcelId) {
                    const loadedParcel = await getParcel(eventId, parcelId);
                    parcel = preLoadFormatting(loadedParcel, context);
                    recipients = parcel.recipients || recipients;
                }
            } catch (e) {
                console.error(i18n.communication.error.fetchMeetingParcel(String(parcelId || ''), String(eventId)));
            }

            setState({
                ...state,
                ...(parcel ? { parcel } : {}),
                attendees,
                recipients: recipients || [],
                plannerSchedule,
                pending: false,
            });
        }

        if (!initialLoad.current) {
            load();
        }
    }, [state, type, parcelId, context, event, user, searchParams, setSearchParams]);

    useEffect(() => {
        // Using useEffect because it should wait until unsavedForm value is updated
        if (redirectUrl && !unsavedForm) {
            navigate(redirectUrl);
        }
    }, [redirectUrl, unsavedForm, navigate]);

    function preLoadFormatting(parcel: Bizly.Parcel, event: TEventParcelContext) {
        const timeZone = parcel.timeZone || event.timeZone;
        const { date: eventStartDate, time: eventStartTime } = getDateTimeStrings(event.startsAt, timeZone);
        const { date: eventEndDate, time: eventEndTime } = getDateTimeStrings(event.endsAt, timeZone);
        const { date: rsvpDueDate, time: rsvpDueTime } = getDateTimeStrings(parcel.rsvpDueAt, timeZone);
        const { date: scheduleDate, time: scheduleTime } = getDateTimeStrings(parcel.sendAt || undefined, timeZone);

        const parcelCopy = {
            ...parcel,
            content: parcel.content || '',
            timeZone,
            startDate: parcel.startDate || eventStartDate,
            startTime: parcel.startTime || eventStartTime,
            endDate: parcel.endDate || eventEndDate,
            endTime: parcel.endTime || eventEndTime,
            rsvpDueDate,
            rsvpDueTime,
            scheduleDate,
            scheduleTime,
            roomBlockEnabled: Boolean(parcel.roomBlockEnabled),
        };

        parcelCopy.vmSharingMethod = event.virtualMeeting ? parcelCopy.vmSharingMethod || 'dont_share' : null;

        return parcelCopy;
    }

    const handleChange = (value: unknown, key: string) => {
        setUnsavedForm(true);
        setState((state: EditParcelState) => {
            const { parcel } = state;

            const newParcel = {
                ...parcel,
                [key]: value,
            };

            return { ...state, parcel: newParcel };
        });
    };

    const handleCopyDates = () => {
        const { meeting } = context;
        if (!meeting) return;
        const { date: startDate, time: startTime } = getDateTimeStrings(meeting.startsAt, meeting.timeZone);
        const { date: endDate, time: endTime } = getDateTimeStrings(meeting.endsAt, meeting.timeZone);

        handleChange(startDate, 'startDate');
        handleChange(endDate, 'endDate');
        handleChange(startTime, 'startTime');
        handleChange(endTime, 'endTime');
    };

    function preSaveFormat({
        preparationNotes,
        serviceNotes,
        facilitationNotes,
        scheduleDate,
        scheduleTime,
        ...parcel
    }: ParcelFormState) {
        const updatedParcel = { ...parcel };

        const isCopying = parcel.sent;
        const newSubject = isCopying ? i18n.communication.newSubject(parcel.subject || '') : parcel.subject;
        const schedule = [scheduleDate, scheduleTime].filter(value => !!value);
        const sendAt = schedule.length > 0 ? schedule.join(' ') : null;
        return {
            ...updatedParcel,
            subject: newSubject,
            name: parcel.name,
            surveyTitle: newSubject,
            rsvpDueAt: [parcel.rsvpDueDate, parcel.rsvpDueTime].filter(value => !!value).join(' '),
            sendAt,
        };
    }

    const onParcelDelete = async () => {
        try {
            await deleteParcel(event.id, { id: parcelId });
        } catch (error) {
            if (typeof error === 'string')
                enqueueSnackbar(error, {
                    variant: 'error',
                });
        }
    };

    const onCancel = () => {
        const backUrl = context.asPlaybookParcel
            ? `/playbooks/${context.id}/edit`
            : `/event/${context.id}/communication/${type}`;
        setRedirectUrl(backUrl);
    };

    const handleSaveClick = async ({
        redirect = true,
        scheduleValues,
    }: {
        redirect: boolean;
        scheduleValues?: Partial<ParcelFormState>;
    }) => {
        const { id: eventId } = context;
        const { parcel, recipients } = state;

        const mergedParcel = preSaveFormat({
            ...parcel,
            ...(scheduleValues ? scheduleValues : {}),
            recipients,
        });

        setState({
            ...state,
            parcel: mergedParcel,
            pending: true,
        });

        const updateAParcel = context.asPlaybookParcel ? updatePlaybookParcel : updateParcel;

        try {
            const res =
                parcel.sent && parcel.id ? await copyParcel(parcel.id) : await updateAParcel(eventId, mergedParcel);

            if (res.success) {
                parcelIdRef.current = res.parcel.id;
                const capitalizedType = capitalize(i18n.communication[type]);
                if (redirect) {
                    enqueueSnackbar(i18n.communication.parcelSaved(capitalizedType), {
                        variant: 'info',
                    });
                }
                onParcelSave?.(parcel);
                setState(prevState => ({
                    ...prevState,
                    pending: false,
                }));
                setUnsavedForm(false);
                if (redirect) {
                    const successUrl = context.asPlaybookParcel
                        ? `/playbooks/${eventId}/edit`
                        : `/event/${eventId}/communication/${type}`;
                    setRedirectUrl(successUrl);
                }
            }
        } catch (error) {
            console.error(`Error updating or creating parcel: ${error}`);
            setState({
                ...state,
                pending: false,
            });
        }
    };

    async function handleSendClick() {
        const { id: eventId, attendeeCounts } = context;
        const { attendees, recipients } = state;
        const successUrl = `/event/${eventId}/communication/${type}`;

        try {
            hideModal();
            await handleSaveClick({ redirect: false });
            await sendParcel(eventId, parcelId || parcelIdRef.current);

            if (type === INVITE) {
                const newReciptientCount = attendees.filter(
                    ({ id, status }) => status === 'not sent' && recipients.includes(id)
                ).length;

                if (attendeeCounts && onParcelSend) {
                    const updatedAttendeeCounts = {
                        ...attendeeCounts,
                        notSent: attendeeCounts.notSent - newReciptientCount,
                        invited: attendeeCounts.invited + newReciptientCount,
                    };
                    onParcelSend(updatedAttendeeCounts);
                }
            }
            enqueueSnackbar(i18n.communication.parcelSent(capitalize(i18n.communication[type])), {
                variant: 'info',
            });
            setRedirectUrl(successUrl);
        } catch (error) {
            if (typeof error === 'string')
                enqueueSnackbar(error, {
                    variant: 'error',
                });
        } finally {
            setState({
                ...state,
                pending: false,
            });
        }
    }

    const handleSchedule = async (values: TScheduleForm) => {
        setState((state: EditParcelState) => ({
            ...state,
            parcel: {
                scheduleDate: values.scheduleDate,
                scheduleTime: values.scheduleTime,
            },
        }));
        await handleSaveClick({ redirect: true, scheduleValues: values });
    };

    async function handlePublish() {
        const { id: eventId } = context;
        const successUrl = `/event/${eventId}/communication/${type}`;

        try {
            await handleSaveClick({ redirect: false });
            await publishParcel(eventId, parcelId || parcelIdRef.current);
            enqueueSnackbar(i18n.communication.parcelPublished(capitalize(i18n.communication[type])), {
                variant: 'info',
            });
            setRedirectUrl(successUrl);
        } catch (error) {
            if (typeof error === 'string')
                enqueueSnackbar(error, {
                    variant: 'error',
                });
        } finally {
            setState({
                ...state,
                pending: false,
            });
        }
    }

    const handleUploadChange = (response: { url: string; title: string }) => {
        if (!response) {
            setShowLogoEditor(false);
            setLogo({ url: '', title: '' });

            handleChange('', CUSTOM_LOGO_URL);
            handleChange('', CUSTOM_LOGO);
        } else {
            setShowLogoEditor(true);
            setLogo({ url: response?.url, title: response?.title });

            handleChange(response?.url, CUSTOM_LOGO_URL);
            handleChange(response, CUSTOM_LOGO);
        }
    };

    function renderPreview() {
        const event = context;
        const { parcel, plannerSchedule } = state;

        const { startDate, startTime, endDate, endTime } = parcel;
        const parcelDates = startDate && startTime && endDate && endTime && { startDate, startTime, endDate, endTime };

        const team = {
            url: parcel.customLogo?.url || '',
            title: parcel.customLogo?.title || '',
        };

        return (
            <BizlyThemeOverride>
                <EditParcelPreview
                    isNote={type === NOTE || type === SURVEY}
                    onClose={hidePreview}
                    name={event?.name}
                    parcelName={parcel?.subject}
                    startsAt={event.startsAt}
                    endsAt={event.endsAt}
                    image={parcel.customImage?.url}
                    description={parcel.content}
                    plannerSchedule={plannerSchedule}
                    plannedBy={event.plannedBy ?? {}}
                    parcelDates={parcelDates ? parcelDates : undefined}
                    useParcelDates={!!parcelDates || !parcel.sent}
                    location={parcel.locationName}
                    address={parcel.locationAddress}
                    cityState={parcel.locationCityState}
                    vmSharingMethod={parcel.vmSharingMethod}
                    virtualMeeting={event.virtualMeeting}
                    hasQuestions={hasQuestions}
                    timezone={parcel.sent ? parcel.timeZone : event.timeZone}
                    team={team}
                    type={type}
                    parcel={parcel}
                />
            </BizlyThemeOverride>
        );
    }

    const showPreview = () => setState({ ...state, showPreview: true });
    const hidePreview = () => setState({ ...state, showPreview: false });

    const { parcel, pending } = state;

    const isPublished = useMemo(
        () => !Array.isArray(parcel?.traits) && parcel?.traits?.public?.registrationPage?.published,
        [parcel?.traits]
    );

    const openScheduler = () => {
        setScheduleAnchor(scheduleAnchor ? null : schedulerRef.current);
    };

    function isValidStartEndDate(state: EditParcelState): boolean | undefined {
        const { startDate, endDate } = state?.parcel || {};
        if (!startDate || !endDate) return;

        const now = new Date();
        const parsedStartDate = parseISO(startDate);
        const parsedEndDate = parseISO(endDate);

        const isStartDateValid = isAfter(parsedStartDate, now) || isSameDay(parsedStartDate, now);
        const isEndDateValid = isAfter(parsedEndDate, parsedStartDate) || isSameDay(parsedStartDate, parsedEndDate);

        return isStartDateValid && isEndDateValid;
    }

    const sendDisabled =
        !isValidStartEndDate(state) || !isSendable(parcel, type) || (context.cancelledAt && type === INVITE);
    const sendDisabledRegistrationPage =
        !isValidStartEndDate(state) || !isSendable(parcel, type) || (context.cancelledAt && type === INVITE);

    const SendButtonGroup = (
        <InlineRow justifyContent="flex-end">
            {parcel.sendAt ? (
                <SendButton disabled={sendDisabled} onClick={openScheduler}>
                    {i18n.communication.scheduled}
                </SendButton>
            ) : (
                <SendButton disabled={sendDisabled} onClick={showModal}>
                    {i18n.button.send}
                </SendButton>
            )}
            <div ref={schedulerRef}>
                <HoverableIcon hoverText="Schedule">
                    <SendOptionButton disabled={sendDisabled} onClick={openScheduler}>
                        <ExpandMoreIcon />
                    </SendOptionButton>
                </HoverableIcon>
            </div>
            {scheduleAnchor && (
                <ScheduleFlyout
                    anchor={scheduleAnchor}
                    setAnchor={setScheduleAnchor}
                    onSubmit={handleSchedule}
                    initialValues={
                        parcel.sendAt
                            ? { scheduleDate: parcel.scheduleDate, scheduleTime: parcel.scheduleTime }
                            : undefined
                    }
                />
            )}
        </InlineRow>
    );

    if (state.showPreview) {
        return renderPreview();
    }

    const parcelActionButton = () => {
        if (type === REGISTRATION_PAGE) {
            return (
                <Button
                    disabled={sendDisabledRegistrationPage}
                    onClick={async () => {
                        if (isPublished) {
                            await copyRegistrationSlugToClipboard({ parcel, eventId: context.id });
                            enqueueSnackbar(i18n.communication.copiedURL, {
                                variant: 'success',
                            });
                        } else {
                            await handlePublish();
                        }
                    }}
                >
                    {isPublished ? i18n.button.copyUrl : i18n.button.launch}
                </Button>
            );
        }

        if (type === HOTEL_CONF && parcel.sent) {
            return null;
        }

        if (!parcel.sent && context.editable && onParcelSend && context.cancelledAt && type === INVITE) {
            return <Tooltip title={i18n.communication.meetingCancelled}>{SendButtonGroup}</Tooltip>;
        }

        if (parcel.sent && context.editable) {
            return <Button onClick={() => handleSaveClick({ redirect: true })}>{i18n.button.copy}</Button>;
        }

        return SendButtonGroup;
    };

    const handleAIGeneration = async () => {
        setLoadingAIContent(true);
        const event = context;

        const result = await generateInviteSubjectAndBody(
            event.name || 'My Event',
            event.description || '',
            event.location || '',
            event.startsAt || '',
            event.endsAt || '',
            user.firstName || '',
            user.team?.name || ''
        );

        if (result.success) {
            setState((prevState: EditParcelState) => {
                return {
                    ...prevState,
                    parcel: {
                        ...prevState.parcel,
                        subject: result.result.subject,
                        content: result.result.body,
                    },
                };
            });
            setLoadingAIContent(false);
        }
    };

    if (webRegistrationRedesignFlagEnabled && type === REGISTRATION_PAGE) {
        return (
            <WebRegistrationModal
                open
                state={state}
                context={context}
                isPublished={!!isPublished}
                loading={!unsavedForm && (pending || isEmpty(parcel))}
                onClose={onCancel}
                onParcelDelete={onParcelDelete}
                handleChange={handleChange}
                onSave={(values = {}) => {
                    setUnsavedForm(true);
                    handleSaveClick({ redirect: false, scheduleValues: values });
                }}
                onPublish={handlePublish}
                onCopyLink={async () => {
                    await copyRegistrationSlugToClipboard({ parcel, eventId: context.id });
                    enqueueSnackbar(i18n.communication.copiedURL, {
                        variant: 'success',
                    });
                }}
            />
        );
    }

    return (
        <Column style={{ width: '100%' }}>
            {unsavedPrompt()}
            <StickyHeader
                pageOffset={context.asPlaybookParcel ? SIDE_NAV_WIDTH : SIDE_SMALL_NAV_WIDTH + MEETINGS_NAV_WIDTH}
            >
                {!user.featureFlags?.createMeetingsFlow && (
                    <AlignedRow justifyContent="flex-start">
                        <ActionButton variant="outlined" onClick={onCancel}>
                            {i18n.button.cancel}
                        </ActionButton>
                    </AlignedRow>
                )}
                <AlignedRow justifyContent="flex-end">
                    {type === INVITE && !parcel.sent && context.editable && user.featureFlags?.aiEnabled && (
                        // Hard coded all the data now, will remove later after demo
                        <AIButton disabled={loadingAIContent} variant="outlined" onClick={handleAIGeneration}>
                            <AIIcon width={22} height={22} style={{ marginRight: '6px' }} />
                            {i18n.button.aiAssist}
                        </AIButton>
                    )}
                    {!parcel.sent && context.editable && !isPublished && (
                        <ActionButton
                            disabled={pending}
                            variant="outlined"
                            style={{ marginRight: '1rem' }}
                            onClick={() => handleSaveClick({ redirect: true })}
                        >
                            {i18n.button.save}
                        </ActionButton>
                    )}
                    {type !== HOTEL_CONF && (
                        <ActionButton
                            disabled={pending}
                            variant="outlined"
                            style={{ marginRight: '1rem' }}
                            onClick={showPreview}
                        >
                            {i18n.button.preview}
                        </ActionButton>
                    )}
                    {parcelActionButton()}
                </AlignedRow>
            </StickyHeader>

            {pending || loadingAIContent || !parcel ? (
                <SpinnerOverlay />
            ) : (
                <>
                    {type === REGISTRATION_PAGE && (
                        <EventRegistrationPage
                            context={context}
                            handleChange={handleChange}
                            state={state}
                            handleUploadChange={handleUploadChange}
                            handleCopyDates={handleCopyDates}
                        />
                    )}
                    {type === INVITE && (
                        <PrivateInvite
                            context={context}
                            handleChange={handleChange}
                            state={state}
                            setState={setState}
                            handleUploadChange={handleUploadChange}
                            handleCopyDates={handleCopyDates}
                        />
                    )}
                    {type === NOTE && (
                        <NotePage
                            context={context}
                            handleChange={handleChange}
                            state={state}
                            setState={setState}
                            handleUploadChange={handleUploadChange}
                            hasQuestions={hasQuestions}
                            setHasQuestions={setHasQuestions}
                        />
                    )}
                    {type === SURVEY && (
                        <SurveyPage
                            context={context}
                            handleChange={handleChange}
                            state={state}
                            setState={setState}
                            handleUploadChange={handleUploadChange}
                            hasQuestions={hasQuestions}
                            setHasQuestions={setHasQuestions}
                        />
                    )}
                    {type === HOTEL_CONF && (
                        <HotelConfirmationEmail
                            context={context}
                            handleChange={handleChange}
                            state={state}
                            setState={setState}
                            handleUploadChange={handleUploadChange}
                        />
                    )}
                </>
            )}
            <ConfirmationModal
                prompt={
                    <LogoEditor
                        image={logo}
                        setLogo={setLogo}
                        rotation={rotation}
                        setRotation={setRotation}
                        setCroppedAreaPixels={setCroppedAreaPixels}
                    />
                }
                isActive={showLogoEditor}
            >
                <LogoButtonsRow itemSpacing="smallish">
                    <CloudinaryUploader
                        onUploadSuccess={({ url, title }: { url: string; title: string }) => setLogo({ url, title })}
                    >
                        <TextButton onClick={e => e.preventDefault()}>{i18n.communication.uploadAnother}</TextButton>
                    </CloudinaryUploader>

                    <LogoActionButtons>
                        <Button
                            disabled={isLoading}
                            variant="outlined"
                            style={{ marginRight: '1rem' }}
                            onClick={() => setShowLogoEditor(false)}
                        >
                            {i18n.button.cancel}
                        </Button>
                        {isLoading ? (
                            <Spinner suppressMargin />
                        ) : (
                            <Button
                                onClick={async () => {
                                    const response = await handleLogoUpload();

                                    if (response) {
                                        handleChange(response?.url, CUSTOM_LOGO_URL);
                                        handleChange(response, CUSTOM_LOGO);

                                        setLogo({ url: response?.url, title: response?.title });
                                    }
                                }}
                            >
                                {i18n.button.apply}
                            </Button>
                        )}
                    </LogoActionButtons>
                </LogoButtonsRow>
            </ConfirmationModal>

            {type !== REGISTRATION_PAGE && (
                <ConfirmationModal
                    headline={<>{i18n.communication.confirmation}</>}
                    prompt={<p>{`${i18n.communication.sendConfirmPrompt[type](String(state?.recipients.length))}`}</p>}
                    isActive={modalShown}
                    onDismiss={() => hideModal()}
                    onProceed={handleSendClick}
                    ctaLabel={i18n.button.confirm}
                />
            )}
        </Column>
    );
}
