import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import RadioButtonUncheckedOutlinedIcon from '@mui/icons-material/RadioButtonUncheckedOutlined';
import { Box, Input, InputProps, styled } from '@mui/material';
import { Button } from 'components/Ui-V2/Button/Button';
import TextField from 'components/Ui-V2/InputFields/TextField';
import { QuestionField } from '../../utills';

const StyledInputField = styled(Input)<InputProps & { errror: boolean }>(({ theme: { getColor, EColors }, error }) => ({
    flex: 1,
    boxSizing: 'border-box',
    width: '100%',
    fontSize: '0.875rem',
    border: '1px solid',
    borderColor: error ? getColor(EColors.red) : getColor(EColors.bizlyOSBorder),
    borderRadius: '4px',
    padding: '9px',
    color: getColor(EColors.pureBlack),
    '& .MuiInput-input': {
        padding: 0,
    },
    '& .MuiInput-input:focus': {
        outline: 'none',
        WebkitBoxShadow: 'none',
        boxShadow: 'none',
    },
    '& .MuiInput-input:focus::placeholder': {
        color: getColor(EColors.pureBlack),
        opacity: 0.5,
    },
    '& .MuiInput-input::placeholder': {
        color: error ? getColor(EColors.red) : getColor(EColors.pureBlack),
    },
}));

type QuestionTextProps = {
    type: 'checkbox' | 'radio';
    onQuestionTextChange: (text: string, index: number) => void;
    questionField: QuestionField;
    index: number;
    onQuestionOptionAdd: (index: number) => void;
    onQuestionOptionChange: (value: string, optionIndex: number, index: number) => void;
};

function QuestionMultipleChoice({
    type,
    index,
    questionField,
    onQuestionTextChange,
    onQuestionOptionAdd,
    onQuestionOptionChange,
}: QuestionTextProps) {
    return (
        <>
            <TextField
                name=""
                onChange={e => onQuestionTextChange(e.target.value, index)}
                value={questionField.value}
                error={undefined}
                placeholder="Question"
            />

            {questionField.options?.map((option, optionIndex) => (
                <Box key={optionIndex} display="flex" alignItems="center" gap={1.25}>
                    {type === 'radio' ? (
                        <RadioButtonUncheckedOutlinedIcon fontSize="small" color="disabled" />
                    ) : (
                        <CheckBoxOutlineBlankOutlinedIcon fontSize="small" color="disabled" />
                    )}

                    <StyledInputField
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            onQuestionOptionChange(e.target.value, optionIndex, index);
                        }}
                        value={option}
                        error={false}
                        placeholder={'Option ' + (optionIndex + 1)}
                        disableUnderline
                    />
                </Box>
            ))}
            <Button
                sx={{ width: 130 }}
                size="small"
                variant="text"
                onClick={() => onQuestionOptionAdd(index)}
                startIcon={<AddCircleOutlineOutlinedIcon />}
            >
                Add Option
            </Button>
        </>
    );
}

export default QuestionMultipleChoice;
