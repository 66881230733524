import { HOTEL_CONF, INVITE, NOTE, SURVEY } from 'pages/EditParcel/utils';
export default {
    // PAGES
    signIn: {
        heading: `ze Sign In`,
        subheading: `ze Enter the email associated with your account, and we'll send you a magic link for instant access.`,
        welcome: `ze Welcome`,
        policyPrefix: `ze By signing up or logging in`,
        validEmailError: `ze Please enter a valid email.`,
        didntReceiveEmail: `ze Didn't receive an email?`,
        resendEmail: `ze Resend Email.`,
        dontHaveAccount: `ze Don't have an account? You can`,
        signUpHere: `ze sign up here.`,
        loginHeading: `ze Hey there`,
        loginSubheading: `ze Sign in to Bizly`,
        submitButtonLabel: `ze Let's go`,
        invalidEmail: `ze Please enter a valid email.`,
        acceptInviteHeading: `ze Welcome to Bizly`,
        sendLink: `ze Send Link`,
        agreement: `ze By continuing, you agree to our`,
        tos: `ze Terms of Service`,
        privacyPolicy: `ze Privacy Policy`,
        ssoLoginRequired: `ze SSO Login Required`,
        ssoLoginDescription: `ze Your account requires SSO login. Please click the button below to proceed with SSO authentication.`,
        ssoLoginProceed: `ze Proceed to SSO Login`,
        emailSent: `ze Email Sent!`,
        emailSentDescription: `ze You'll receive a magic link if there's an account associated with the provided email. The link expires in 30 minutes and can only be used once to sign in.`,
        backToSignIn: `ze Back to Sign In`,
        authenticating: `ze Authenticating...`,
    },
    userMenu: {
        settings: `ze Settings`,
        myProfile: `ze My Profile`,
        integrations: `ze Integrations`,
        approvals: `ze Approvals`,
        teamSettings: `ze Team Settings`,
        signOut: `ze Sign Out`,
    },
    teamSelector: {
        switchTeam: `ze Switch Team`,
        rejectedInvite: `ze Rejected Invite`,
    },
    userProfile: {
        enterInfo: (teamName: string) => `ze Enter your info to join the ${teamName}ze  team`,
        setUpYourProfile: `ze Set Up Your Profile`,
        phoneNumberError: `ze Please enter a valid phone number.`,
        select: `ze Select`,
        policyPrefix: `ze By saving`,
        completeYourProfile: (teamName: string) => `ze Let's complete your ${teamName}ze  profile.`,
        errorSavingProfile: `ze An error occurred while saving your profile. Please try again.`,
    },
    approvals: {
        header: `ze Approvals`,
        meetingName: `ze Meeting name`,
        meetingOwner: `ze Meeting owner`,
        venueName: `ze Venue name`,
        proposalTotal: `ze Proposal total`,
        proposalLink: `ze Proposal link`,
        status: `ze Status`,
        actionDate: `ze Date of action`,
        reason: `ze Reason`,
        newBudget: `ze New budget`,
        viewLink: `ze View link`,
        showMore: `ze Show more`,
        showLess: `ze Show less`,
    },
    teamSettings: {
        approversEmail: `ze Approver's Email Address`,
        header: `ze Team Settings`,
        profile: `ze Profile`,
        members: `ze Members`,
        teamLogo: `ze Team Logo`,
        teamName: `ze Team Name`,
        teamColor: `ze Team Color`,
        homepageBannerMessage: `ze Homepage Banner Message`,
        internalReferenceLabel: `ze Internal Reference Label`,
        internalReferenceFile: `ze Internal Reference File`,
        costCenterFieldLabel: `ze Cost Center Label`,
        costCenterFileField: `ze Cost Center File`,
        redirectMessage: `ze Meeting Creation Redirect Message`,
        cancellationMessage: `ze Cancellation Information Message`,
        cancellationEmails: `ze  Cancellation Emails`,
        defaultInternalReference: `ze Internal Reference`,
        defaultCostCenterReference: `ze Cost Center`,
        cancellationEmail: {
            emailName: `ze Email Name`,
            buttonLabel: `ze Button Label`,
        },
        contactTeamAdmin: `ze Contact your team admin to change these settings.`,
        removeCustomizationWarning: `ze This will remove all customizations to your team and restore default values. Your changes will not be finalized until you hit "Save".`,
        requireBudgetApproval: `ze Require Budget Approval`,
        maximumBudgetLimit: `ze Maximum Budget Limit`,
        maximumAccommodationRooms: `ze Maximum Accommodation Rooms`,
        limitDescription: `ze Set Accommodation Limits for your Inquiry`,
        limitPerNight: `ze Limit per night`,
        limitPerNightDescription: `ze Set the maximum number of guest rooms, a planner can inquire about, PER NIGHT for a meeting.`,
        limitPerMeeting: `ze Limit per meeting`,
        limitPerMeetingDescription: `ze Set the maximum number of guest rooms, a planner can inquire about, IN TOTAL for a meeting.`,
        membersTable: {
            active: `ze Active`,
            invited: `ze Invited`,
            rejected: `ze Rejected`,
            inviteByEmail: `ze Invite member by email`,
            name: `ze Name`,
            role: `ze Role`,
            status: `ze Status`,
            lastActive: `ze Last Active`,
            teamAdmin: `ze Team Admin`,
            teamMember: `ze Team Member`,
        },
        error: {
            teamNameRequired: `ze Team Name is required.`,
            teamLogoRequired: `ze Team Logo is required.`,
            teamColorValid: `ze Team Color must be a valid color hex.`,
            invalidEmail: `ze Invalid Email.`,
            alreadyAMember: `ze Already a member`,
            budgetApproverRequired: `ze Budget Approver is required.`,
            maximumBudgetRequired: `ze Maximum Budget is required.`,
            invalidFile: `ze Please select a CSV file.`,
            roomLimitRequired: `ze Please set either of the maximum accommodation rooms limit.`,
        },
    },
    homepage: {
        welcome: (user: string) => `ze Welcome, ${user}`,
        welcomeToBizly: `ze Welcome to Bizly`,
        playbooksHeader: `ze Meeting Templates`,
        createNewMeeting: `ze Create New Meeting`,
        createMeeting: `ze Create new meeting`,
        createPlaybook: `ze Create a Template`,
        viewPlaybooks: `ze View Templates`,
        upcomingMeetings: `ze Upcoming Meetings`,
        viewAllMeetings: `ze View All Meetings`,
        healthAndSafetyInfo: `ze Health and safety are top of mind for all. Prior to planning your meeting, be sure to check out these
            considerations for how to mitigate risk and make everyone more comfortable.`,
        viewDetailsHere: `ze View Details Here`,
        activity: `ze Activity`,
        untitled: `ze Untitled`,
        header: {
            eventConcierge: `ze Event Concierge`,
        },
        sideNav: {
            meetings: `ze Meetings`,
            search: `ze Search`,
            events: `ze Events`,
            workspaces: `ze Workspaces`,
            templates: `ze Templates`,
            venues: `ze Venues`,
            reporting: `ze Reporting`,
            help: `ze Help`,
            hi: (user: string) => `ze Hi, ${user}`,
            hiUser: `ze Hi, user`,
            creditScore: (credit: number) => `${credit}ze  credits`,
            creditDescription: `ze Buy and earn credits for event AI`,
            sourceSiteTitle: `ze Business Travel`,
        },
        createMeetingModal: {
            header: (template: string) => `ze Create a new ${template}ze  meeting`,
            headerNoTemplate: `ze Create a new meeting`,
            almostThere: `ze Almost there!`,
            copyLine1: `ze To create a new meeting, we need some basic information to get you started.`,
            copyLine2: `ze You'll be able to view and edit this later if something changes.`,
            completeRequiredFields: `ze Please complete required fields.`,
            invalidDateRange: `ze End date cannot be earlier than the start date.`,
            viewPlaybook: `ze View Template`,
            meetingName: `ze Meeting Name`,
            location: `ze Event Location`,
            budget: `ze Budget`,
            playbook: `ze Template`,
            cventEvent: `ze Cvent Event`,
            meetingId: `ze Meeting ID`,
            costCenter: `ze Cost Center`,
            department: `ze Department`,
            meetingType: `ze Meeting Type`,
            recordId: `ze Record ID`,
            noPlaybook: `ze No Template`,
            none: `ze None`,
            virtualGatherings: `ze For virtual gatherings, input your current location to set the time zone.`,
            teamProtocol: (teamName: string) => `${teamName}ze  has a meeting creation protocol.`,
            teamEventProtocol: (teamName: string) => `ze Please follow ${teamName}ze 's event creation protocol.`,
            requiredFieldsError: `ze The required fields were not provided.`,
            wrongUserError: `ze This link is for a different user.`,
            learnMore: `ze Learn More`,
            objetiveTitle: `ze What event are you planning?`,
            objectiveDescription: `ze Describe your event and objective`,
            objectiveDetailDescription: `ze Add a detailed description to help our AI generate details for your event`,
            eventName: `ze Event Name`,
            eventDescription: `ze Objective`,
            bookTitle: `ze What do you need to book for this event?`,
            selectVenueType: `ze Select one of the following venue types`,
            selectAllThatApply: `ze Select all that apply`,
            eventSpace: `ze Event Space`,
            hotelRoomBlock: `ze Hotel Room Block`,
            privateDining: `ze Private Dining`,
            groupActivity: `ze Group Activity`,
            conferenceRoom: `ze Conference Room`,
            office: `ze Office / Coworking`,
            dateTitle: `ze When is your event?`,
            youCanEditThisLater: `ze Select your event dates here. You can edit them later.`,
            singleDay: `ze Single Day`,
            multiDay: `ze Multi Day`,
            guestsTitle: `ze How many guests are you expecting?`,
            numberDescription: `ze Enter the number of guests`,
            locationTitle: `ze Where do you want to host this event?`,
            locationDescription: `ze Enter your location`,
            fixedLocation: `ze Fixed Location`,
            recommendLocation: `ze Recommend Location`,
            find: `ze Find`,
            paymentTitle: `ze How much do you want to pay?`,
            paymentDescription: `ze Add your budget, excluding transportation, vendors, taxes & fees etc`,
            grandTotal: `ze Grand Total`,
            perPerson: `ze Per Person`,
            additionalInfoTitle: `ze Additional Information`,
            additionalInfoDescription: `ze Please provide this information to proceed`,
            eventInfoDescription: `ze Want to create an event from a template?`,
            clickHere: `ze Click here.`,
            sampleDescription: (nextMonthName: string, year: string) =>
                `ze Example: Sales Team Offsite in New York, from ${nextMonthName}ze  18-23, ${year}ze . 28-30 guests, 28-30 guest rooms. Need conference/meeting rooms daily from 9am - 5pm. Breakfast and catered lunch - $24,000 total budget.`,
        },
        actionItems: {
            title: `ze Action Items`,
            reviewBudgetApprovalRequest: `ze Review Budget Approval Request`,
            headCells: {
                meetingName: `ze Meeting Name`,
                date: `ze Date`,
                owner: `ze Owner`,
                approvalRequestDetails: `ze Approval Request Details`,
                proposalExpiryDate: `ze Proposal Expiry Date`,
            },
        },
        actionItemsModal: {
            rejectedProposalRequest: `ze Rejected proposal request`,
            approvedProposalRequest: `ze Approved proposal request`,
            notes: (eventName: string, requestNotes: string) =>
                `ze Your event, ${eventName}ze  has a proposal that has come in above budget. \n\n${requestNotes}`,
            meetDetails: {
                meetingBudget: `ze Meeting Budget`,
                linkToMeeting: `ze Link to The Meeting`,
            },
            proposalDetails: {
                title: `ze Proposal Details`,
                venueName: `ze Venue Name`,
                roomHeldUntil: `ze Room Held Until`,
                proposalTotal: `ze Proposal Total`,
                meetingType: `ze Meeting Type`,
                linkToProposal: `ze Link to The Proposal`,
            },
            reasonForBudgetApproval: `ze Reason for Budget Approval`,
            currentBudget: `ze Current Budget`,
            actionRequired: `ze Action Required`,
            approve: `ze Approve`,
            reject: `ze Reject`,
            budgetValidation: `ze New budget amount cannot be less than or equal to the proposal total`,
            totalAmountNote: `ze Please note: The proposal total amount excludes taxes and fees.`,
            rejectPrompt: `ze Are you sure you want to reject this request?`,
            rejectReasonLabel: `ze Please provide reason:`,
            reasonPlaceholder: `ze Enter reason for rejection`,
            rejectReasonValidation: `ze Please provide the reason.`,
        },
        eventButtons: {
            newEvent: `ze New Event`,
            startFromTemplate: `ze Start From Template`,
            pricesToBeat: `ze Prices To Beat`,
            createAnEvent: `ze Create an event`,
        },
        eventTableActions: {
            yourEvents: `ze Your Events`,
            all: `ze All`,
            upcoming: `ze Upcoming`,
            createdByYou: `ze Created by you`,
            actionItems: `ze Action Items`,
            grid: `ze Grid`,
            list: `ze List`,
        },
        eventFilters: {
            type: `ze Event Type`,
            status: `ze Status`,
            startDate: `ze Event Start Date`,
            owner: `ze Owner`,
            collaborators: `ze Collaborator`,
        },
        eventStateMessages: {
            default: {
                createdByOwner: {
                    title: `ze Host your next event`,
                    content: `ze You haven't created any event yet. Let's change that!`,
                },
            },
            admin: {
                all: {
                    title: `ze Nothing planned yet`,
                    content: `ze Your team's events will appear here.`,
                },
                upcoming: {
                    title: `ze No upcoming events`,
                    content: `ze Your team's upcoming events will appear here.`,
                },
                actionItems: {
                    title: `ze Nothing on your to-do list`,
                    content: `ze Time to kick back and relax (or start planning your next big event!).`,
                },
            },
            user: {
                all: {
                    title: `ze Nothing planned yet`,
                    content: `ze Your events will appear here.`,
                },
                upcoming: {
                    title: `ze Nothing planned yet`,
                    content: `ze Your upcoming events will appear here.`,
                },
            },
        },
    },
    workspaces: {
        title: `ze Workspaces`,
        mainDescription: `ze Get instant access to a global network of over 3,000 flexible workspaces in 120+ countries.`,
        bookWorkspace: `ze Book A Workspace`,
        upcoming: `ze Upcoming`,
        past: `ze Past`,
        cancelled: `ze Cancelled`,
        all: `ze All`,
        header: {
            location: `ze Location`,
            reservationId: `ze Reservation ID`,
            workspaceType: `ze Workspace Type`,
            peopleCount: `ze No. of People`,
            reservationDate: `ze Reservation Date`,
            reservationTime: `ze Reservation Time`,
            action: `ze Action`,
        },
        drawer: {
            mainTitle: `ze Find the right workspace`,
            mainSubTitle: `ze Instantly book a network of 3,000+ flexible workspaces in 120+ countries.`,
            meetingRoomsTitle: `ze Book meeting rooms`,
            meetingRoomsSubTitle: `ze Instantly book professionally-managed meeting rooms.`,
            coworkingDesksTitle: `ze Book coworking desks`,
            coworkingDesksSubTitle: `ze Instantly book desks in a shared office space.`,
            privateOfficesTitle: `ze Book private office spaces`,
            privateOfficesSubTitle: `ze Book a fully serviced office space by the day, week or month.`,
            locationPlaceholder: `ze Enter a city, area, or location name`,
            bookLongerAndSave: `ze Book Longer & Save`,
            whatsIncluded: `ze What’s Included:`,
            wifiDescription: `ze High Speed Internet & WIFI`,
            agentDescription: `ze Onsite Professional Community Team`,
            whiteboardDescription: `ze Whiteboard or Flipchart`,
            coworkingOfficeDescription: `ze Dedicated workspace in a collaborative work environment`,
            furnitureDescription: `ze Private office furniture`,
            screenshareDescription: `ze Screen Sharing (TV or Projector)`,
            roomSetup: `ze Room Setup`,
        },
        venueTypes: {
            meetingRooms: `ze Meeting Rooms`,
            meetingRoomsDescription: `ze Professional spaces to meet or pitch.`,
            coworkingDesks: `ze Co-Working Desks`,
            coworkingDesksDescription: `ze Desks in shared office space.`,
            privateOffices: `ze Private Offices`,
            privateOfficesDescription: `ze Full furnished and ready to go offices.`,
            deskCount: `ze No. of Desks`,
            peopleCount: `ze No. of People`,
        },
    },
    notifications: {
        title: `ze Notifications`,
        newMessagesCopy: (newCount: number, total: number) => `ze You have ${newCount}ze  new of ${total}ze  messages.`,
        inquiryTurnedDown: `ze Inquiry Turned Down`,
        uploadContract: `ze Upload Contract`,
        proposalReceived: `ze Proposal Received`,
        messageReceived: `ze Message Received`,
        proposalCancelled: `ze Proposal Cancelled`,
        proposalUpdated: `ze Proposal Updated`,
        expirationDateUpdated: `ze Expiration Date Updated`,
        expirationDateRejected: `ze Expiration Date Rejected`,
        uploadFinalSpend: `ze Upload Final Spend`,
        viewProposal: `ze View proposal`,
        filters: `ze Filters`,
        all: `ze All`,
        unread: `ze Unread`,
        read: `ze Read`,
    },
    footer: {
        privacy: `ze Privacy`,
        helpCenter: `ze Help Center`,
        copyright: (year: number) => `ze © ${year}ze  Bizly, Inc. All rights reserved.`,
    },
    meetingsPage: {
        eventDetails: `ze Event Details`,
        meetings: `ze Meetings`,
        scheduled: `ze Scheduled`,
        unscheduled: `ze Unscheduled`,
        drafts: `ze Drafts`,
        department: `ze Department`,
        createNewMeeting: `ze Create New Meeting`,
        cancelledOn: (dateTime: string) => `ze Cancelled on ${dateTime}`,
        meetingRoom: `ze Meeting room`,
        sideNav: {
            overview: `ze Event Overview`,
            inquiryBuilder: `ze Inquiry Builder`,
            venues: `ze Venues`,
            venuesShortList: `ze Venues Shortlist`,
            proposals: `ze Proposals`,
            contracting: `ze Contracting`,
            guestlist: `ze Guest List`,
            communication: `ze Communication`,
            virtual: `ze Virtual`,
            search: `ze Search`,
            inquiry: `ze Inquiry`,
            invites: `ze Invites`,
            expense: `ze Expense`,
            spendTracker: `ze Spend Tracker`,
            invite: `ze Invite`,
            registration: `ze Registration`,
            hotel: `ze Hotel`,
            survey: `ze Survey`,
            note: `ze Note`,
        },
        noTemplate: `ze No template`,
        meetingDetails: `ze Meeting details`,
        template: `ze Template`,
        templateDescription: `ze Template Description`,
        none: `ze None`,
        cventEvent: `ze Cvent Name`,
        meetingName: `ze Meeting name`,
        startDate: `ze Event Start Date`,
        startTime: `ze Event Start Time`,
        endDate: `ze Event End Date`,
        endTime: `ze Event End Time`,
        dateDisabled: {
            leadingText: `ze To change dates, go to `,
        },
        eventDescription: `ze Event Description`,
        descriptionNote: `ze What's the description of this event?`,
        budget: `ze Budget`,
        totalBudget: `ze Budget (Grand total)`,
        perPersonBudget: `ze Budget (Per person)`,
        costCenter: `ze Cost center`,
        internalReference: `ze Internal reference`,
        meetingType: `ze Meeting type`,
        meetingFormat: `ze Meeting format`,
        virtual: `ze Virtual`,
        // for virtual meeting
        inPerson: `ze In-person`,
        // for in-person meeting
        hybrid: `ze Hybrid`,
        // for hybrid meeting
        venueDescription: `ze Send your inquiry to up to 4 venues at a time and get custom proposals. Add additional venues when slots open up.`,
    },
    search: {
        searchPlaceholder: `ze Search meetings...`,
        meetings: `ze Meetings`,
        scheduled: `ze Scheduled`,
        unscheduled: `ze Unscheduled`,
        drafts: `ze Drafts`,
        meetingList: {
            activity: `ze Activity`,
            cancelled: `ze Cancelled`,
        },
    },
    meetingDashboard: {
        eventEditTooltip: `ze Edit Name/Description/Purpose`,
        cancellationDetails: `ze Cancellation Details`,
        teamCancellationProtocol: (teamName: string) => `${teamName}ze  Cancellation Protocol`,
        deleteConfirmation: `ze Event will be marked as cancelled.

        Existing venue booking inquiries and proposals will be closed and venues and attendees will not be notified.

        No further venue inquiries will be possible. Be sure to follow up with the venue and/or attendees.`,
        cancelWithNotes: `ze Cancel with Note`,
        cancelError: `ze Please fill in the cancellation notes.`,
        untitledMeeting: `ze Untitled Meeting`,
        purpose: `ze Purpose`,
        cancellationProtocolInfo: (teamName: string) => `${teamName}ze  has a cancellation protocol.`,
        cancellationNotes: `ze Cancellation Notes`,
        notesPlaceholder: `ze Leave notes for internal tracking`,
        meetingBriefing: `ze Meeting Briefing`,
        meetingCompleted: `ze Completed meeting sucessfully`,
        uploadFinalSpendDescription: `ze Now that your meeting is over, don’t forget to enter in final spend and upload the final invoice. This will help you track your meeting spending.`,
        completeMeetingDescription: `ze Now that your meeting is over, don’t forget to mark your meeting as complete on Contracting page.`,
        goToSpendTracker: `ze Go to Spend Tracker`,
        goToContracting: `ze Go to Contracting Page`,
        headerSection: {
            settings: {
                meetingSettings: `ze Meeting Settings`,
                meetingType: `ze Meeting Type`,
                costCenter: `ze Cost Center`,
                internalReference: `ze Internal Reference`,
                budget: `ze Budget`,
                internal: `ze Internal`,
                external: `ze External`,
                department: `ze Department`,
            },
            documents: {
                yourMeetingFiles: `ze Your Meeting Files`,
                subheading: `ze These can be viewed by you and other organizers, but not your guests.`,
                emptyFiles: `ze There are no files for this meeting.`,
            },
            spendTracking: {
                spendTracking: `ze Spend Tracking`,
                budget: `ze Budget`,
                estimate: `ze Estimate`,
                finalSpend: `ze Final Spend`,
                budgetSaved: `ze Budget was successfully saved.`,
                estimatesSaved: `ze Meeting estimate and contracts were successfully saved.`,
                invoicesSaved: `ze Meeting spend and invoices were successfully saved.`,
                finalSpendTracker: `ze Final Spend Tracker`,
                uploadFinalInvoice: `ze Upload Final Invoice`,
                maxFileUploadSize: `ze Max file upload size`,
                finalSpendingNotAvailable: `ze Final spend tracking becomes available once a venue has been booked.`,
                fileTooLarge: (filename: string) => `ze File ${filename}ze  is too large.`,
                spendEstimate: `ze Spend Estimate`,
                uploadContracts: `ze Upload Contracts`,
            },
            collaborators: {
                invite: `ze Invite`,
                addCollaborators: `ze Add Collaborators`,
                canEdit: `ze can edit`,
                canView: `ze can view`,
                enterEmail: `ze Please enter a valid email address.`,
                askAdmin: `ze Don't see who you’re looking for? Ask your administrator to ensure they've been added to your Bizly team.`,
                invitePrompt: `ze Invite by name or email`,
                suggestion: `ze By adding someone new to collaborate with, you’ll invite them to join your team.

                They’ll just need to activate their account to join!`,
                collaboratorsInvited: `ze Collaborators invited to this meeting.`,
                collaboratorsAdded: `ze Collaborators added to this meeting.`,
                collaboratorCanEdit: `ze Collaborator can now edit this meeting.`,
                collaboratorCanView: `ze Collaborator can now view this meeting.`,
                inviteError: `ze Something went wrong when inviting collaborators.`,
                addError: `ze Something went wrong when adding collaborators.`,
                emailInviteError: `ze Some emails could not be invited.`,
                emailAddError: `ze Some emails could not be added.`,
                changePermissionError: `ze Something went wrong when changing the permission.`,
                noCollaborators: `ze No Collaborators Added`,
                collab: `ze Share`,
            },
        },
        checklist: {
            myChecklist: `ze My Checklist`,
            defaultSubheading: `ze Follow these steps to organize your complete group experience.`,
            startedSubheading: `ze Here are some to-dos to get you started.`,
            progressSubheading: `ze You're making great progress.`,
            finishedSubheading: `ze You're all done!`,
            onYourWay: `ze You're already on your way!`,
            greatStart: `ze Great start.`,
            doingGreat: `ze You're doing great.`,
            nice: `ze Nice!`,
            onFire: `ze You're on fire.`,
            nailIt: `ze Nailed it.`,
            preMeeting: `ze Pre meeting`,
            dayOf: `ze Day of`,
            postMeeting: `ze Post meeting`,
            taskCompleted: (completed: number, total: number) => `${completed}ze  of ${total}ze  completed.`,
        },
        venueSection: {
            recommendedVenues: `ze Recommended Venues`,
            exploreMoreVenues: `ze Explore More Venues`,
            venues: `ze Venues`,
            venue: `ze Venue`,
            findVenue: `ze Find a venue`,
            defaultSubheading: `ze Find a great venue to help make your meeting shine. Here are some suggestions in the area.`,
            noCitySubheading: `ze Let us know where you're looking by selecting a city up top, then we'll help you find a great venue for your meeting.`,
            hasInquiriesSubheading: `ze You've submitted an inquiry. If venues can accommodate your meeting, they'll respond with a proposal.`,
            hasBookedSubheading: (date: string) => `ze Your booking's confirmed for ${date}ze .`,
            unknownDates: `ze Unable to retrieve the dates of your booking.`,
            cancelled: `ze Your meeting has been cancelled.`,
        },
        roomBlockSection: {
            headline: `ze Room Block`,
            description: `ze This will help you determine the total number of rooms (room block) that need to be booked with the venue.`,
            formSectionHelper: `ze Invitees will be able to see the below fields on their invite and select dates and room type.`,
            on: `ze On`,
            off: `ze Off`,
            roomTypeLabel: `ze Requested room type`,
            preferredRoomType: `ze Preferred room type`,
            roomTypePlaceholder: `ze Select room type`,
            single: `ze Single`,
            double: `ze Double`,
            suite: `ze Suite`,
            handicapAccessible: `ze Handicap Accessible`,
            accessible: `ze Accessible`,
            additionalNotes: `ze Additional notes`,
            notesPlaceholder: `ze Notes`,
            respondDeadlineLabel: `ze Invitees should submit room block details by`,
            postCutoffContact: `ze Post-cut-off contact`,
            exportCsv: `ze Export Excel file`,
            importNote: `ze You can import this data when building/editing your inquiry from the `,
            inquiryBuilderPage: `ze Inquiry Builder page (Accommodations section)`,
            importFromRoomBlock: `ze Import from Room Block`,
        },
        vendorsSection: {
            virtualMeeting: `ze Virtual Meeting`,
            defaultSubheading: `ze Add a virtual meeting to make your meeting more inclusive!`,
            hasVirtualMeetingSubheading: `ze Manage your virtual meeting below. You can share this with attendees in invites.`,
            addVirtualMeeting: `ze Add Virtual Meeting`,
        },
        guestListSection: {
            guestList: `ze Guest List`,
            defaultSubheading: `ze A perfect meeting needs the perfect audience! Here, we'll build and manage your guest list.`,
            guestDetails: `ze Guest Details`,
            deleteGuest: `ze Delete Guest`,
            revokeInvite: `ze Revoke Invite`,
            invalidRoomBlock: `ze Room block details will not be added as the guest has not accepted the invitation or has declined.`,
        },
        communicationSection: {
            communication: `ze Communication`,
            defaultSubheading: `ze Send beautifully branded invites, notes and reminders to your guests.`,
        },
    },
    playbooks: {
        apply: `ze Apply`,
        playbooks: `ze Templates`,
        subHeading: `ze Templates pre-populate meeting details. Create your own or use ones provided to you.`,
        recommendationSubHeading: `ze Choose from our well crafted pre-built meeting templates.`,
        createNewPlaybook: `ze Create New Template`,
        createMeeting: `ze Create Meeting`,
        personalTitle: `ze My Templates`,
        sharedTitle: (teamName: string) => `${teamName}ze  Templates`,
        sharedTitleGeneric: `ze Your Team's Teamplates`,
        globalTitle: `ze Recommended Templates`,
        personalSubheading: `ze These are the templates you’ve created.`,
        sharedSubheading: `ze These are the templates your team members have created.`,
        globalSubheading: `ze These are templates available to all.`,
        filterByCategories: `ze Filter by categories`,
        playbookForTag: (name: string) => `${name}ze  Template`,
        playbookModalCreate: `ze Create a new template`,
        playbookModalCreatePrompt: `ze What would you like to name this template?`,
        playbookModalError: `ze A name is required to create a template.`,
        eventStateMessages: {
            all: {
                title: `ze No templates created.`,
                content: `ze Templates created by you and your team, along with Bizly recommended templates, will appear here.`,
            },
            teamTemplates: {
                title: `ze No team templates created.`,
                content: `ze Templates published by your team will appear here.`,
            },
            createdByOwner: {
                title: `ze No templates created by you yet.`,
                content: `ze Templates created by you, but yet not published, will appear here.`,
            },
            recommendedTemplates: {
                title: `ze No Bizly recommended templates here.`,
                content: `ze There are no templates recommended by Bizly at this moment.`,
            },
        },
    },
    playbook: {
        publishToTeam: `ze Publish To Team`,
        publishHeadline: `ze Publish Template with Team`,
        publishPrompt: `ze By publishing a template, all members of your team will have access to it when creating a future meeting.`,
        deletePlaybook: `ze Delete Template`,
        deletePrompt: `ze By deleting this template you will be removing it from your Templates. If it is shared with a team, they will no longer have access to it as well.`,
        view: `ze View`,
        createEvent: `ze Create Event`,
        edit: `ze Edit`,
        delete: `ze Delete`,
    },
    venues: {
        title: `ze Explore Venues`,
        subHeading: `ze Below is our directory of venues. Feel free to search for the perfect space for your upcoming meeting.`,
        venue: `ze Venue`,
        venueSubtitle1: `ze Find a great venue to help make your meeting shine. Here are some suggestions in the area.`,
        venueSubtitle2: `ze Let us know where you’re looking by selecting a city up top, then we’ll help you find a great venue for your meeting.`,
        venueSubtitle3: `ze You’ve submitted an inquiry. If venues can accommodate your meeting, they’ll respond with a proposal.`,
        createMeeting: `ze Create Meeting`,
        noVenuesFound: `ze No venues were found for your search and criteria. If you think this is an error, please reach out to your company administrator.`,
    },
    playbookWizard: {
        steps: {
            basicDetails: `ze Basic Details`,
            search: `ze Search`,
            inquiry: `ze Inquiry`,
            communication: `ze Communication`,
            collaboration: `ze Collaboration`,
            preview: `ze Preview`,
        },
        header: {
            saved: `ze Saved!`,
            updatePlaybook: `ze Update Template`,
        },
        basicInfo: {
            coverPhoto: `ze Cover Photo`,
            namePrompt: `ze What would you like to call this template?`,
            description: `ze Description`,
        },
        collaboration: {
            collaborators: `ze Collaborators`,
            addCollaborators: `ze Add Collaborators`,
            collaboratorsDescription: `ze Add pre-set collaborators to this template so you always have the right people involved to support the meeting.`,
            documents: `ze Documents`,
            resourcesDescription: `ze Pre-attach documents to this template.`,
            collaboratorsInvited: `ze Collaborators invited to this template.`,
            collaboratorsAdded: `ze Collaborators added to this template.`,
            noDocumentsUploaded: `ze No Documents Uploaded`,
        },
        preview: {
            venueSearchFilters: `ze Venue Search Filters`,
            inquiry: `ze Inquiry`,
            communications: `ze Communications`,
            collaboration: `ze Collaboration`,
        },
        nameRequiredError: `ze Template name is required`,
    },
    meetingSearch: {
        meetingName: `ze Event Name`,
        status: `ze Status`,
        date: `ze Event Start Date`,
        owner: `ze Owner`,
        activity: `ze Last Activity`,
    },
    integrations: {
        connectAnIntegration: `ze Connect an Integration?`,
    },
    proposals: {
        home: `ze Home`,
        inquiries: `ze Inquiries`,
        bookings: `ze Bookings`,
        listings: `ze Listings`,
        messages: `ze Messages`,
        calender: `ze Calendar`,
        anayltics: `ze Analytics`,
        settings: `ze Settings`,
    },
    proposalForm: {
        selectRejectReason: `ze Please select a Turn Down Reason`,
        provideOtherRejectReason: `ze Please provide a note to explain your selection.`,
        viewContract: `ze View Contract`,
        viewContract1: `ze View Contract 1`,
        viewContract2: `ze View Contract 2`,
        changeCurrency: `ze Change Currency`,
        changeCurrencyDescription: `ze Update the currency for the entire proposal.
        Previously entered prices values will not be converted.`,
        sections: {
            contactInformation: `ze Contact Information`,
            guestRooms: `ze Guest Rooms`,
            meetingSpaces: `ze Spaces`,
            finishingTouches: `ze Finishing Touches`,
            proposalStatus: `ze Proposal Status`,
            rooms: `ze Rooms`,
        },
        banner: {
            extensionRequestCopy: `ze You have received an extension request for this proposal`,
            editExpirationDate: `ze Edit Expiration Date`,
            rejectRequest: `ze Reject Request`,
        },
        header: {
            createProposal: `ze Create a proposal`,
            greetingsCopy: (name: string, companyName: string) =>
                `ze Thank you for accepting an inquiry from ${name}ze  at ${companyName}ze . Please complete this form to send a proposal for this upcoming meeting.`,
            clientDetails: `ze Client Details`,
            clientAgreement: `ze Client Agreement`,
            meetingDetails: `ze Meeting Details`,
            cancelled: `ze Cancelled`,
            datesAreFlexible: `ze Dates are flexible`,
            clientNotes: `ze Client Notes`,
            alternativeDates: `ze Alternative Dates`,
            alternativeDatesPlaceholder: `ze Indicate which dates are available here`,
            commissionableEvent: `ze Commissionable Event`,
            bizlyIATANumber: `ze Bizly IATA number`,
            commissionPayout: `ze Commission payout details located in the`,
            contract: `ze contract`,
        },
        status: {
            inquiryAlreadyRejected: `ze The inquiry has already been rejected`,
            inquiryRejected: `ze The inquiry has been rejected`,
            proposalAlreadyCancelled: `ze The proposal has already been cancelled`,
            proposalCancelled: `ze The proposal has been cancelled`,
            inquiryInvalid: `ze Unfortunately, this inquiry is no longer open to new proposals.`,
            inquiryCancelled: `ze Unfortunately, the inquiry has been withdrawn.`,
            proposalSubmitted: `ze Your proposal has been submitted.`,
            proposalSubmittedWithUserChange: `ze Congrats! Your proposal has been submitted.`,
            deadlineCopy: (clientName: string, deadline: string) =>
                `${clientName}ze  has until ${deadline}ze  to respond.`,
            proposalAccepted: (clientName: string) => `ze Congrats! Your proposal was accepted by ${clientName}ze .`,
            proposalAcceptedCopy: `ze When the contract is complete, it will appear here.`,
            proposalRejected: `ze Unfortunately, your proposal was rejected.`,
        },
        button: {
            withdrawProposal: `ze Withdraw Proposal`,
            createProposal: `ze Create Proposal`,
            editProposal: `ze Edit Proposal`,
            viewProposal: `ze View Proposal`,
            turnDownInquiry: `ze Turn Down Inquiry`,
        },
        modal: {
            rejectInquiryTitle: `ze Are you sure you want to reject the inquiry?`,
            rejectExtensionTitle: `ze Are you sure you want to reject this extension request?`,
            cancelProposalTitle: `ze Are you sure you want to cancel your proposal?`,
            rejectReason: `ze Turn Down Reason`,
            reasonPlaceholder: `ze Select a reason`,
            notes: `ze Notes`,
            notesPlaceholder: `ze Other dates available? Can only accommodate a portion of the inquiry? Let the client know.`,
            notesRequired: `ze Please provide notes`,
        },
        contactInformation: {
            feeDescription: `ze Bizly is a small meetings tool used to source venues like yours! It is free for you to use and there are no hidden fees. For more information, `,
            clickHere: `ze CLICK HERE`,
            heading: `ze Let's start with the basics.`,
            description: `ze If your proposal is accepted, we'll pass your contact information along to our client
            so they can reach out and work through the details.`,
            firstName: `ze First Name`,
            lastName: `ze Last Name`,
            title: `ze Job Title`,
            phoneNumber: `ze Phone Number`,
            email: `ze Email Address`,
            agreementLabel: `ze I have downloaded and read the`,
            clientAgreement: `ze client agreement`,
            agreementLabel2: `ze Until signed, this is non-binding and I can add any questions/comments to the notes section of this proposal form.`,
            error: {
                phoneError: `ze Please enter a valid phone number.`,
                readClientAgreement: `ze Please make sure to read the client agreement.`,
            },
        },
        guestRoom: {
            heading: `ze Next, let's talk guest rooms.`,
            description: `ze This meeting requires overnight accommodations. Please share what's available below.
            Don't worry! You'll be able to provide additional notes and documents before submitting.
            If you cannot accommodate one or more nights, just leave it blank.`,
            noGuestRoomRequest: `ze There are no guestroom requests.`,
            commissionHeading: `ze Are these guest room rates commissionable?`,
            commissionCopy: `ze You can refer to the attached document or reach out to the NSO for this client.`,
            roomsAvailable: `ze rooms available`,
            addRooms: `ze Add Rooms`,
            noRooms: `ze No Rooms`,
            guestNumber: `ze No. of Rooms`,
            roomImage: `ze Room Image`,
            roomType: `ze Room Type`,
            roomRate: `ze Room Rate`,
            commissionable: `ze Commissionable`,
            commissionRate: `ze Commission %`,
            suggestedCommissionRate: `ze Suggested rate: 10%`,
            commissionRateNote: `ze You must submit a commission rate for room nights to be considered.`,
            resortFee: `ze Resort Fee`,
            occupancyTax: `ze Occupancy Tax`,
            copyRoomDetails: `ze Copy Room Details Below`,
            copyRoomDetailsToAll: `ze Copy Room Details To All`,
            error: {
                fillAll: `ze Please make sure to fill out all fields for:`,
                answerRequest: `ze Please make sure to answer the request for:`,
                notifyCommissionable: `ze Please let the client know if guest rooms are commissionable`,
            },
        },
        eventSpaces: {
            heading: `ze Almost there! Propose the spaces that will work best.`,
            eventSpacesDescription: `ze If you or someone on your team has sent a proposal before, we may have some saved spaces!
            Please select a saved space or provide a new one. You can use the same space multiple times.
            You'll be able to provide additional notes and documents before submitting.`,
            noMeetingSpaceRequest: `ze There are no meeting space requests.`,
            clientSpaceRequest: `ze Client Space Request`,
            setupRequests: `ze Setup Request(s)`,
            setupRequestsDescription: `ze This is how the client wants the space to be set up.
            What can be accommodated?`,
            selectSpace: `ze Select a space above to begin responding.`,
            spaceIndex: (index: number) => `ze Space ${index}`,
            spaceCount: (count: number) => `${count}ze  ${count > 1 ? `ze Spaces` : `ze Space`}`,
            guestsFormatter: (value: string) => `${value}ze  Guests`,
            seating: `ze Seating`,
            dailyRentalRate: `ze Daily Room Rental Rate`,
            dailyFB: `ze Daily F/B Minimum`,
            perPersonRate: `ze Per Person Rate`,
            minGuestsNo: `ze Minimum Number of Guests`,
            salesTax: `ze Sales Tax`,
            serviceCharge: `ze Service Charge`,
            gratuity: `ze Gratuity`,
            dailyRate: `ze Daily Room Rate`,
            feesAndTaxes: `ze Fees and Taxes`,
            audioVisual: `ze Audio/Visual`,
            audioVisualProvided: `ze Audio/Visual will be provided by a third party`,
            diningStyle: `ze Dining Style`,
            foodBeverage: `ze Food/Beverage`,
            changeUnitHeading: `ze Change Your Preferred Measurement Unit`,
            changeUnitDescription: `ze This will only update your settings.
            Others will continue to see values converted into their preferred units.`,
            noSpaceAvailable: `ze No Space Available`,
            createNewSpace: `ze Create a New Space`,
            spaceEditor: `ze Space Editor`,
            spaceEditorDescription: `ze You can update your space here.`,
            selectASpace: `ze Select a space...`,
            name: `ze Name`,
            image: `ze Image`,
            description: `ze Meeting Description`,
            playbookDescription: `ze Template Description`,
            descriptionPlaceholder: `ze Description that shows up on our platform. Make it awesome!`,
            size: `ze Size`,
            maxCapacity: `ze Max Capacity`,
            noImageAvailable: `ze No Image Available`,
            capacity: `ze capacity`,
            editSpace: `ze Edit Space`,
            people: `ze people`,
            roomRatesCommissionable: `ze Are Daily Room Rates commissionable?`,
            fbCommissionable: `ze Is F&B commissionable?`,
            copySpaceBelow: `ze Copy Space Details Below`,
            copySpaceToAll: `ze Copy Space Details To All`,
            error: {
                noVenueSpaceId: `ze respond with a space or "No Space Available"`,
                timeAndSetup: `ze fill out the time and setup field`,
                avIds: `ze respond to all Audio/Visual requests`,
                fb: `ze respond to all Food/Beverage requests`,
                minGuest: `ze specify the minimum number of guests`,
                minFb: `ze specify the food and beverage minimum`,
                pricing: `ze fill out pricing fields`,
                roomRateCommission: `ze fill out room rental commission rate`,
                fbCommission: `ze fill out F/B commission rate`,
            },
        },
        proposalNotes: {
            heading: `ze You're ready to submit your proposal.`,
            subheading: `ze Please confirm that your proposal is complete and accurate.
            This is your chance to share any additional information that will be useful in evaluating your proposal.`,
            additionalDetails: `ze Additional Details`,
            additionalDetailsDescription: `ze Use this space to answer client questions or to address any additional concessions. Feel free to upload menus, A/V pricing, and more.`,
            addAttachment: `ze Add Attachment`,
            spaceHeldUntil: `ze Space Held Until`,
            spaceHeldUntilDescription: `ze Provide the latest date you can hold this space.`,
            error: {
                spaceHeldEnd: `ze Please be sure to provide a Space Held Until date for this proposal`,
                spaceHeldEndInvalid: `ze Space Held Until date must be in the future.`,
                minDate: `ze Date cannot be in the past`,
            },
        },
    },
    venue: {
        tabs: {
            search: `ze Search`,
            inquiries: `ze Inquiries`,
            venues: `ze Venues`,
            proposals: `ze Proposals`,
        },
        selectedVenues: `ze Selected Venues`,
        createNewEvent: `ze Create New Event`,
        searchVenues: `ze Search venues`,
        closeCopy: `ze This venue cannot be added because it is temporarily closed. If you think this is a mistake, please`,
        contactSupport: `ze contact our support team`,
        inquirySubmissionHelper: `ze Adding a venue will automatically submit an inquiry to the venue.`,
        inquirySubmissionConfirm: `ze Do you want to add this venue to your inquiries?`,
        addToInquiry: `ze Add to inquiry`,
        addedToInquiry: `ze Added to inquiry`,
        nonPreferredVenueConfirmation: `ze You are adding a non-preferred venue to your inquiry. It is always suggested that you reach out to preferred venues.`,
        addToVenueQueue: `ze Are you sure you want to add this to your venue queue?`,
        remove: `ze Remove`,
        notBookable: `ze Not Bookable`,
        showingResultsNear: (query: string) => `ze Showing you results near "${query}ze "`,
        noResultsForFilters: `ze We couldn't find any venues matching your search. Try removing filters`,
        noResultsForQuery: (query: string) => `ze We couldn't find any venues near "${query}ze ". Try again`,
        viewMap: `ze View map`,
        lockedFiltersCopy: `ze This Template doesn't allow filters to be edited.`,
        filters: `ze Filters`,
        filtersWithCount: (count: number) => `ze Filters ${count}`,
        filterBy: `ze Filter by`,
        where: `ze Where`,
        searchPlaceholder: `ze Search for exact venue name or location`,
        when: `ze When`,
        attendeeCount: `ze How many attendees`,
        noLocationsForQuery: (query: string) => `ze We couldn't find any locations matching "${query}ze ". Try again.`,
        goToVenuePage: `ze Go to Venue Page`,
        temporarilyClosed: `ze Temporarily Closed`,
        preferred: `ze Preferred`,
        propertyId: `ze Property ID`,
        externalUrlHelper: `ze Please use internal system to reserve`,
        featureHeadline: `ze Featured Amenities`,
        maximumCapacity: `ze Max Capacity`,
        numberOfSpaces: `ze Number of Spaces`,
        carbonInfo: `ze Carbon estimates based on a 1500sq. ft. space with 30 attendees at this venue.

            This number is calculated using a hotel sustainability index which has generally been accepted by international standards bodies for sustainability measurement.`,
        goToCarbonHelp: `ze To learn more about Carbon Scores, go to `,
        carbonHelpLink: `ze our Help Page.`,
        carbonMeasurement: `ze Carbon Measurement`,
        noData: `ze No data`,
        featuredSpaces: `ze Listed Space`,
        maxGuestCapacity: (capacity: number) => `${capacity}ze  people`,
        addMeetingSpace: `ze Add Meeting Space`,
        duplicate: `ze Duplicate`,
        addGuestRooms: `ze Add Guest Room`,
        addVirtualMeeting: `ze Add a Virtual Meeting`,
        virtualMeetingInfo: `ze Virtual Meeting Information`,
        recommendation: `ze Recommendation`,
        essentials: `ze Essentials`,
        total: `ze Total`,
        details: `ze Details`,
        messageVenue: `ze Message Venue`,
        yourVenueContact: `ze Your venue contact`,
        meetingRooms: `ze Meeting rooms`,
        description: `ze Description`,
        banquetRounds: `ze Banquet rounds`,
        boardRoomConference: `ze Board room conference`,
        classroom: `ze Classroom`,
        cocktailRounds: `ze Cocktail rounds`,
        crescentRounds: `ze Crescent rounds`,
        eshaped: `ze E-shaped`,
        hollowSquare: `ze Hollow square`,
        perimeterSeating: `ze Perimeter seating`,
        talkShow: `ze Talk show`,
        theater: `ze Theater`,
        tshaped: `ze T-shaped`,
        ushaped: `ze U-shaped`,
        viewAllPhotos: (count: number) => `ze View all ${count}ze  photos`,
        imageNotAvailable: `ze Image not available`,
        filterForm: {
            dinovaOnly: `ze Dinova Only`,
            preferredVenuesOnly: `ze Preferred Venues Only`,
            venueType: `ze Venue Type`,
            notFiltered: `ze Not Filtered`,
            distance: `ze Distance from Searched Location`,
            brands: `ze Brands`,
            attendeeCount: `ze Number of Attendees`,
            quickFilters: `ze Quick Filters`,
        },
        inquiry: {
            aboveBudgetTooltip: `ze Above budget. Go to Proposal page to request approval.`,
            newBudgetApproved: `ze New Budget Approved`,
            aboveMaximumBudget: `ze Above budget`,
            addANote: `ze Add a note`,
            addNotePlaceholder: `ze Please consider the below items as you add a note:
- New requested budget amount (including VAT/taxes & service fees)
- Reason for increased budget request
- Were any options available that were within budget? If yes, reason for using a venue that is over budget.`,
            approvalRequested: `ze Approval Requested`,
            uhOh: `ze Uh-oh!`,
            bannerCopy: `ze Set a date for your meeting and add guest rooms (with room count) or a meeting space (with time, setup and guest count) to submit an inquiry.`,
            goToPlanner: `ze Go to Planner`,
            noSlotsRemaining: `ze No Slots Remaining`,
            noSlotsRemainingCopy: `ze Looks like you're an inquiry submitting Jedi and used up all four of your slots. Sit back and watch the proposals roll in.`,
            viewInquiries: `ze View Inquiries`,
            buildYourInquiry: `ze Build Your Inquiry`,
            reviewInquiry: `ze Review/Edit Inquiry`,
            buildInquiryDescription: `ze Once you add the venues you are interested in, we just need to know your meeting needs.`,
            buildInquirySubDescription: `ze Once you're confident everything is accurate, you're ready to submit!`,
            buildHelper1: `ze You can inquire with up to 4 venues at a time.`,
            buildHelper2: `ze Click the + icon on a venue card to add it to the inquiry.`,
            submitInquiry: `ze Submit Inquiry`,
            submitInquiries: `ze Submit Inquiries`,
            submitForApproval: `ze Submit for Approval`,
            closeInquiry: `ze Close Inquiry`,
            viewInquiry: `ze View Inquiry`,
            cancelInquiry: `ze Cancel Inquiry`,
            needsApproval: `ze Needs Approval`,
            nudge: `ze Nudge`,
            nudgeSuccess: `ze Nudged successfully`,
            nudgeDescription: `ze Nudge a venue to remind them to respond. You can only use the nudge button once every 24 hours.`,
            lastNudged: (time: string) => `ze Last nudged ${time}ze .`,
            // ex: Last nudged 8 hours ago.
            viewInquiryDetails: `ze View Details`,
            buildInquiry: `ze Build Inquiry`,
            viewProposal: `ze View Proposal`,
            viewProposalDetails: `ze Proposal Details`,
            inquiryDetails: `ze Inquiry Details`,
            detailsSubHeading: `ze We've automagically pulled the details from your meeting to give you a head start on your inquiry.`,
            datesFlexible: `ze Dates are flexible`,
            notes: `ze Notes`,
            notesForVenue: `ze Notes for the Venue`,
            notesPlaceholder: `ze Add additional details that will be helpful to the venue.`,
            notesPlaceholder2: `ze Include any additional details or information`,
            notesPlaceholder3: `ze Please Note: Approval may take up to a few days. You will receive an email notification when action has been taken. For an update on your approval process, please reach out to your company admin.`,
            meetingDate: `ze Meeting Date`,
            warning: `ze Warning`,
            warningLine1: `ze Editing your inquiry will cancel all submitted inquiries and reject received proposals.`,
            warningLine2: `ze Do you still want to continue to edit your inquiry?`,
            withinMaximumBudget: `ze Within budget`,
            requestApproval: `ze Request Approval`,
            requestExtension: `ze Request Extension`,
            yourInquiries: `ze Your Inquiries`,
            editInquiry: `ze Edit Inquiry`,
            exportProposals: `ze Export Proposals`,
            export: `ze Export`,
            compare: `ze Compare`,
            compareProposals: `ze Compare Proposals`,
            inquirySubheading: `ze If you've submitted your inquiries, our concierge team is following up with the venues to get you the fastest responses and most accurate proposals. You'll be notified via email when the proposals come in. Look at the below venue statuses to follow along with the progression. Thanks for your patience.`,
            listingSubHeading: `ze Your submitted inquiries will appear here and you'll be able to view, accept and decline them.`,
            noActiveVenueInquiries: `ze You currently have no active venue inquiries or proposals. Source venues and send inquiries to get started.`,
            guestRoomRental: `ze Guest Room Rental`,
            roomRentalTotal: `ze Room rental total`,
            guestRoomsCommissionable: `ze Guestrooms are Commissionable.`,
            amountDescription: `ze Amount excludes taxes and fees.`,
            guestRoomsPerNight: `ze Guest rooms per night`,
            requestRejected: `ze Request Rejected`,
            requestRejectedInfo: `ze Please submit this inquiry to another venue or reach out to your admin for assistance.`,
            roomName: `ze Room Name`,
            roomRate: `ze Room Rate`,
            occupancyTax: `ze Occupancy Tax`,
            resortFee: `ze Resort Fee`,
            meetingSpace: `ze Meeting Space`,
            guestsNumber: (count: number) => `${count}ze  attendees`,
            minGuests: `ze Min. Guests`,
            guests: `ze Guests`,
            perPerson: `ze Per Person`,
            fbMin: `ze F&B Min.`,
            roomRental: `ze Room Rental`,
            roomSizeWithUnit: (unit: string) => `ze Room Size (${unit}ze )`,
            roomMaxCapacity: `ze Max Capacity`,
            serviceCharge: `ze Service Charge`,
            salesTax: `ze Sales Tax`,
            gratuity: `ze Gratuity`,
            meetingSpaceTotal: `ze Meeting space total`,
            minDayNumber: (index: number) => `ze Day ${index}ze  Min.`,
            eventSpaces: `ze Event Spaces`,
            spaces: `ze Spaces`,
            proposalTotal: `ze Proposal Total`,
            proposalTotalHelper: `ze *exclusive of taxes and fees`,
            submittedOn: (time: string) => `ze Submitted on ${time}`,
            rejectedOn: (time: string) => `ze Rejected on ${time}`,
            error: {
                noVenues: `ze There are no venues to send inquiries to.`,
                noBookings: `ze There are no requested bookings to send.`,
                dateRequired: `ze Date is required`,
                startTimeRequired: `ze Start time is required`,
                startDateInvalid: `ze Start date cannot be in the past`,
                endTimeRequired: `ze End time is required`,
                setupRequired: `ze Setup is required`,
                attendeesRequired: `ze Attendees must be greater than 0`,
                dateInvalid: `ze Date cannot be in the past`,
                roomsRequired: `ze Please note: You have submitted 0 number of rooms for one of your dates.`,
                roomsContinue: `ze Do you still want to continue?`,
                confirmRooms: `ze Note: Number of rooms for 0.`,
                linkRequired: `ze A link is required for a virtual meeting`,
                protocolRequired: `ze Make sure the link includes the protocol (http or https)`,
                nudge: `ze Failed to nudge.`,
                noNotes: `ze Please add a note before submitting for approval.`,
                serverError: `ze Something went wrong. Please try again.`,
                approvalRequestSuccess: `ze Approval request sent`,
                exceedMaxRoomNumber: (max: number) => `ze Number of rooms per day cannot exceed ${max}`,
                exceedTotalMaxRoomNumber: (max: number) => `ze Total number of rooms cannot exceed ${max}`,
                inquiryValidation: `ze No inquiry details or venues added`,
            },
        },
        planner: {
            heading: `ze Planner`,
            subHeading: `ze Every great meeting was once a blank canvas – and here's yours!
            We'll build the structure and details of your day, including meeting
            spaces, guest rooms, A/V and catering.`,
            schedule: `ze Schedule`,
            scheduleDescription: `ze Start with the dates and times of your meeting`,
            scheduled: `ze Scheduled`,
            addDayBefore: `ze Add a day before`,
            addDay: `ze Add a day`,
            date: `ze Date`,
            startTime: `ze Start Time`,
            endTime: `ze End Time`,
            meetingSpaceNamePlaceholder: `ze Name this meeting space`,
            roomSetup: `ze Room Setup`,
            selectSetup: `ze Select Setup`,
            noOfAttendees: `ze No. of Attendees`,
            audioVisual: `ze Audio/Visual`,
            FB: `ze Food & Beverage`,
            diningStyle: `ze Dining Style`,
            noOfRooms: `ze No. of Rooms`,
            notes: `ze Notes`,
            serviceType: `ze Service Type`,
            selectService: `ze Select service`,
            link: `ze Link`,
            additionalMeetingInfo: `ze Additional Meeting Information`,
            roomAccordionDescription: `ze Determine what meeting spaces, private dining rooms, A/V, catering services you need.`,
            accommodations: `ze Accommodations`,
            guestRoomsLabel: `ze Nights / Guestrooms`,
            guestRoomsDescription: `ze Identify how many guest rooms are needed for overnight attendees`,
            virtualMeetings: `ze Virtual Meeting`,
            type: `ze Type`,
            typeDescription: `ze Select the right hosting platform to support virtual attendees`,
            invalidTimeRange: `ze End time cannot be the same or earlier than the start time`,
            checkIn: `ze Check In`,
            checkOut: `ze Check Out`,
            noDatesSelected: `ze No dates selected`,
            noDatesAdded: `ze No dates added`,
            numberOfNightsNeed: `ze Number of nights needed`,
            savedSuccessfully: `ze Saved successfully`,
        },
        proposal: {
            detailedBreakdown: `ze Detailed Breakdown`,
            proposal: `ze Proposal`,
            roomHeldUntil: `ze Room held until`,
            spaceHeldUntil: `ze Space held until`,
            extensionRejectedOn: `ze Extension rejected on`,
            extensionRequestSentOn: `ze Extension request sent on`,
            requestSent: `ze Request successfully sent.`,
            proposalExpired: `ze Proposal Expired`,
            extensionRejected: `ze Extension Rejected`,
            proposalHasExpired: `ze Unfortunately, this proposal has expired.`,
            extensionRejectedAt: (venueName: string, timestamp: string, preposition?: string) =>
                `${venueName}ze  has already rejected an extension request ${preposition ? preposition + `ze  ` : ``}${timestamp}ze .`,
            expiryExtensionRejected: (venueName: string, timestamp: string, preposition?: string) =>
                `ze Unfortunately, this proposal has expired. ${venueName}ze  has already rejected an extension request ${preposition ? preposition + `ze  ` : ``}${timestamp}ze . You can still contact the venue to have them update the expiration date.`,
            extensionReqSentAt: (timestamp: string, preposition?: string) =>
                `ze You sent an extension request ${preposition ? preposition + `ze  ` : ``}${timestamp}ze .`,
            contactVenueToUpdateExpiry: `ze You can still contact the venue to have them update the expiration date.`,
            extensionRequested: `ze Extension Requested`,
            resendRequestInquiry: `ze Do you want to resend the request?`,
            resendRequest: `ze Resend Request`,
            cannotAcceptAfterExpiry: `ze This proposal can't be accepted after the expiration date has been reached.`,
            requestExtensionInquiry: `ze Would you like to request an extension?`,
            acceptConfirmDescription: `ze Accepting this proposal will notify the venue and cancel all remaining active inquiries and proposals. Once you accept a proposal, it cannot be changed.`,
            rejectConfirmDescription: `ze Rejecting this proposal will notify the venue. This process cannot be undone.`,
            cancelConfirmDescription: `ze This will notify the venue of your cancellation.`,
            flexibleDates: `ze Flexible Dates`,
            nextStepHeadline: `ze Finalize Meeting`,
            noFileSelected: `ze No file selected`,
            dropHere: `ze Drag and drop your file here or browse.`,
            finalizeContract: `ze Finalize Contract`,
            markAsComplete: `ze Mark as Complete`,
            uploadSignedDocs: `ze Upload Signed Documents`,
            maxFileUploadSize: `ze Max file upload size: 40MB.`,
            previouslyUploadedFiles: `ze Previously Uploaded Files`,
            newlyUploadedFiles: `ze Newly Uploaded Files`,
            uploadNotice: `ze Upload limit: 2 files. To replace your file, simply upload the new file. The most recent file will get replaced.`,
            uploadResource: `ze Upload limit: 2 files. To replace a file, simply delete one of the files and upload a new one.`,
            estimatesHelper: `ze Estimates become available once a proposal has been accepted.`,
            noContractDescription: `ze You will see your Contracting and Payment steps here once you accept a proposal from a venue.`,
            emptyContractTitle: `ze No Contracts`,
            emptyContractDescription: `ze You don’t have any contract to review yet. Once you accept a venue’s proposal, contracting and payment steps will show up here.`,
            contracting: `ze Contracting`,
            defaultVenueDocCopy: `ze The contract spells out the details and rates for your meeting, along with mutually agreed terms. Before both parties have signed is the best time to negotiate!`,
            defaultVenueDocAltCopy: `ze You will need to contact the venue for a copy of their contract.`,
            estimateTotalRequired: `ze Estimated Total field is required.`,
            contractRequired: `ze At least one contract document is required.`,
            finalSpendRequired: `ze Final Spend field is required.`,
            platformFee: `ze Platform Fee`,
            tabs: {
                contracting: `ze Contracting`,
                signDocs: `ze Sign Docs`,
                uploadDocs: `ze Upload Docs`,
                paymentDetails: `ze Payment details`,
                finalDetails: `ze Final details`,
            },
            sign: {
                headline: `ze Complete and sign documents`,
                helper: `ze Fill out the details of your meeting and forward to your venue contact.`,
                acknowledgement: `ze A few things to be aware of as you're reviewing the agreement:`,
                note1: `ze Check the dates, times and number of attendees for any meeting space`,
                note2: `ze Check the dates and number of guest rooms for each day`,
                note3: `ze Review the cancellation section of the agreement so you're aware of the financial liability should you need to cancel`,
                note4: `ze Don't sign a contract if there's any uncertainty that the meeting will occur`,
                uploadHeadline: `ze Upload signed documents`,
                defaultUploadCopy: `ze Once both parties have signed, upload the final contract, fill out the estimated totals, and then the venue will be marked as booked.`,
            },
            payment: {
                headline: `ze Provide your payment details`,
                prompt: `ze The venue will send a credit card authorization form or a payment link. You may also be required to make payment on site.`,
                minSpend: `ze Your estimate is less than the spend minimum.`,
                maxSpend: `ze Your estimate is greater than the spend maximum.`,
                requestSignedDoc: `ze Please upload your signed documents to request a card for your meeting.`,
                requestPayment: `ze Please use the link below to generate an American Express vPayment account to pay the venue directly.`,
                vPayment: `ze Pay with vPayment`,
                requestExpress: `ze Request American Express vPayment`,
                vPayDescription: `ze Virtual payments are a super easy way to make payments to the venue and will make expenses a breeze! Just a few questions and we'll get an account for your meeting generated straight away.`,
                name: `ze Name`,
                estimatedSpend: `ze Estimated Spend`,
                accountActivationThrough: `ze This account will be active from`,
                termsConfirmation: `ze You have read and agreed to the terms and conditions and are aware of our company policies for use.`,
                cardHeadline: `ze Success! Here's your account information.`,
                cardSubheadline: `ze Keep this information secure just as you would your own credit card number. If you need to access it again, check back here and we can pull it up again for you. If you have any issues or questions, please reach out to your administrator.`,
                accountActiveSince: `ze Your account will be active from:`,
                number: `ze Number`,
                expDate: `ze Exp Date`,
                cvc: `ze CVC`,
                billingZip: `ze Billing zip`,
            },
            noInfo: `ze No information available`,
            finalizeHeadline: `ze Finalize your meeting details`,
            finalizeSubHeadline: `ze The venue contact is your go-to for all other meeting details, like catering selections, room setup requirements and any questions about the venue.
            Reminder: Upload your final invoice and totals into the spend tracker when your event is over. You'll find it on your meetings dashboard.
            `,
            noProposalsAcceptedYet: `ze No Proposals Accepted Yet`,
            noVenueBookedYet: `ze No Venue Booked Yet`,
            totalSpendHelper: `ze Total spend tracking becomes available once a venue has been booked.`,
        },
        types: {
            corporateOffice: `ze Corporate Office`,
            hotel: `ze Hotel`,
            restaurant: `ze Restaurant`,
            activity: `ze Activity`,
            conferenceCenter: `ze Conference Center`,
            bookableOffice: `ze Bookable Office`,
            bar: `ze Bar`,
            brewery: `ze Brewery`,
            winery: `ze Winery`,
            museum: `ze Museum`,
            theater: `ze Theater`,
            uniqueVenue: `ze Unique Venue`,
            eventVenue: `ze Event Venue`,
            professionalWorkspace: `ze Professional Workspace`,
            coworkingSpace: `ze Coworking Space`,
            yourSearchLocation: `ze Your Search Location`,
        },
        notFound: {
            title: `ze Venue Not Found`,
            message: `ze Hmm, we can't seem to locate this venue. It's possible they've closed or are no longer partnered
                    with Bizly. If you believe this is a mistake, please contact our Concierge Team, and we'll
                    investigate.`,
            confirmation: `ze Understood!`,
        },
    },
    message: {
        haveQuestion: `ze Have a question?`,
        sendMessageToVenue: (venueName: string) => `ze Send ${venueName}ze  a message.`,
        inputPlaceholder: `ze Send a message...`,
        bookingCancelled: `ze This booking has been cancelled.`,
        congrats: `ze Congrats!`,
        congratsDescription: `ze Now that you've accepted a proposal, you'll work directly with the venue to sign a contract, provide payment info and finalize all the details of your meeting.`,
    },
    communication: {
        communication: `ze Communication`,
        invitesCommunication: `ze Invites & Communication`,
        description: `ze Let's get your message out there. Here you can create branded invites and send meeting updates.`,
        playbook: `ze template`,
        meeting: `ze meeting`,
        deletePrompt: (type: string) =>
            `ze Deleting this email will remove it from your ${type}ze . This process cannot be undone.`,
        // type is either 'playbook' or 'meeting
        invite: `ze Invite`,
        noteSurvey: `ze Note / Survey`,
        create: `ze Create`,
        copiedURL: `ze Copied published URL`,
        meetingCancelled: `ze Meeting is cancelled`,
        recipientCount: (count: number = 0) => `${count}ze  ${count !== 1 ? `ze Recipients` : `ze Recipient`}`,
        sent: `ze Sent`,
        draft: `ze Draft`,
        event: `ze Event`,
        launched: `ze Launched`,
        schedule: `ze Schedule`,
        scheduled: `ze Scheduled`,
        scheduleFor: `ze Schedule for`,
        reschedule: `ze Reschedule`,
        rescheduleFor: `ze Reschedule for`,
        deleteScheduleConfirm: `ze Are you sure you want to delete this schedule?`,
        newSubject: (name: string) => `ze Copy of ${name}`,
        note: `ze note`,
        parcelCreated: (type: string) => `${type}ze  Created`,
        parcelSaved: (type: string) => `${type}ze  Saved`,
        parcelSent: (type: string) => `${type}ze  Sent`,
        parcelPublished: (type: string) => `${type}ze  launched`,
        virtualMeetingDetailsText: (serviceName: string) =>
            `ze Virtual attendance is available${serviceName ? `ze  by ${serviceName}ze  services` : ``}`,
        messageHeadline: (firstName: string, lastName: string) =>
            `${[firstName, lastName].join(`ze  `).trim()}ze  has sent you a message:`,
        firstName: `ze First Name`,
        firstNamePlaceholder: `ze Enter first name`,
        lastName: `ze Last Name`,
        lastNamePlaceholder: `ze Enter last name`,
        phone: `ze Phone Number`,
        email: `ze Email`,
        rsvpComments: `ze Itinerary/Travel Notes`,
        rsvpCommentsPlaceholder: `ze Add a note for the meeting organizer.`,
        attendanceType: `ze How do you plan to attend?`,
        inPerson: `ze In-person`,
        virtual: `ze Virtual`,
        needOvernight: `ze Do you need overnight accommodation?`,
        rsvpHeadline: (firstName: string, lastName: string, name: string) =>
            `${[firstName, lastName].join(`ze  `).trim()}ze  has invited you to ${name}`,
        clickVMLink: `ze To join, just click the link below or copy and paste it into your internet browser:`,
        clickToRespond: `ze Click here to respond`,
        registerToAttend: `ze Register to Attend`,
        notAttending: `ze Not Attending`,
        yourDetails: `ze Your Details`,
        additionalInformation: `ze Additional Information`,
        accommodationRequirements: `ze Accommodation Requirements (if any)`,
        accommodationDescription: `ze Once you select and submit the room type, we'll confirm with the venue and get them booked.`,
        roomTypeHelper: `ze Your selected room type is not guaranteed.`,
        expired: `ze Expired`,
        contactEmailOnExpiration: (dateTime: string, email: string) =>
            `ze This room block submission period expired on ${dateTime}ze . If you need assistance, please contact ${email}ze .`,
        contactSenderOnExpiration: (dateTime: string) =>
            `ze This room block submission period expired on ${dateTime}ze . If you need assistance, please contact the person who gave this link to you.`,
        expiration: `ze Expiration`,
        expirationCopy: (dateTime: string) => `ze The ability to select room blocks will expire on ${dateTime}ze .`,
        dietaryDefaultPrompt: `ze Do you have any dietary needs?`,
        dietaryPlaceholder: `ze No dietary needs selected.`,
        shareVirtualmeetingDetails: `ze Share Virtual Meeting details`,
        notShareVirtualmeetingDetails: `ze Do not share Virtual Meeting details`,
        subHeaderInvite: `ze Your invite will be sent as an email with buttons for guests to select 'Register' or 'Not attending'. If they select 'Register', they will be taken to a form where they can provide additional details. Customize the email and the form below.`,
        subHeaderNote: `ze Create a simple branded message. You can include a customized survey below.`,
        inviteName: `ze Invite Name`,
        noteName: `ze Note Name`,
        editInvite: `ze Edit Invite`,
        inviteTitle: `ze Private Event Invitation`,
        editNoteSurvey: `ze Edit Note/Survey`,
        createNoteSurvey: `ze Create a Note/Survey`,
        recipients: `ze Recipients`,
        recipientsPrompt: `ze Who would you like to send this to?`,
        attendees: `ze Attendees`,
        subject: `ze Subject`,
        subjectPlaceholder: `ze Enter a subject line for the email`,
        eventSubjectPlaceHolder: `ze Enter title for your event registration page`,
        headline: `ze Headline`,
        headlinePlaceholder: `ze Enter a message title for the email`,
        message: `ze Message`,
        messagePlaceholder: `ze Include a message in the email`,
        image: `ze Banner Image`,
        addImage: `ze Add Image`,
        imageUploadDescription: `ze Upload a JPG or PNG file (under 2MB, minimum 680 x 400px). Your team color will be used if no custom image is uploaded.`,
        imageUploadPrompt: `ze Add a custom image to your event registration page.`,
        hotelBannerUploadPrompt: `ze Add a custom image to your email.`,
        hotelLogoUploadPrompt: `ze Add a custom logo to your email.`,
        eventDates: `ze Event Dates`,
        copyToInvite: `ze Copy to Invite`,
        copyBelowToRegistrationPage: `ze Copy below to Registration page`,
        venue: `ze Venue`,
        venueName: `ze Venue Name`,
        streetAddress: `ze Street Address`,
        cityState: `ze City, State`,
        noVirtualMeetings: `ze No Virtual Meetings have been setup in Agenda.`,
        rsvpEditHeadline: `ze Customize RSVP Form`,
        rsvpEditDescription: `ze Customize the fields on your RSVP form to collect information from your attendees. Responses are only visible to you and collaborators.`,
        draftCreated: `ze Draft created`,
        logo: `ze Logo`,
        addLogo: `ze Add Logo`,
        logoUploadDescription: `ze Upload a JPG or PNG file (under 2MB, minimum 200 x 200px). You can resize and crop your logo after uploading it.`,
        uploadAnother: `ze Upload another`,
        error: {
            fetchPlaybook: (id: string | number) => `ze Could not fetch parcels for template ${id}`,
            fetchMeeting: (id: string | number) => `ze Could not fetch parcels for meeting ${id}`,
            fetchMeetingParcel: (parcelId: string, eventId: string) =>
                `ze Could not fetch parcel ${parcelId}ze  for meeting ${eventId}`,
            createParcel: (type: string) => `ze Error creating ${type}ze !`,
            schedulePast: `ze You cannot schedule for a past date/time.`,
        },
        publicEventRegistration: `ze Public Event Registration`,
        publicEventRegistrationDescription: `ze Create a web-based event registration.`,
        privateInvite: `ze Private Invite`,
        privateInviteDescription: `ze Invite specific people to your event.`,
        surveyDescription: `ze Find out how your event was received.`,
        noteDescription: `ze Communicate some details about your event`,
        surveyNoteTitle: `ze Survey / Note`,
        surveyNoteDescription: `ze Send out a survey or a note for your event.`,
        eventRegistrationTitle: `ze Create an event registration page`,
        privateInviteTitle: `ze Invite and communicate with your guests`,
        hotel_conf: `ze Hotel Confirmation Email`,
        hotelConfLabel: `ze Send out hotel confirmation email`,
        hotelConfTitle: `ze Hotel Confirmation Email`,
        hotelConfDescription: `ze Send out event details and hotel communication number.`,
        registration_page: `ze Registration Page`,
        survey: `ze Survey`,
        rsvp: `ze RSVP`,
        eventRegistrationHeadline: `ze Public event registration`,
        eventRegistrationSubheader: `ze Create a public event invitation that can be viewed and shared publicly as a landing page.`,
        eventTitle: `ze Event title`,
        eventTitlePlaceholder: `ze Enter title for your event registration page`,
        eventDescription: `ze Event description`,
        eventDescriptionPlaceholder: `ze Add description for your event`,
        publicEventRegistrationTitle: `ze Create an event registration page`,
        sendConfirmPrompt: {
            [INVITE]: (attendees: string) =>
                `ze You are sending a private event invitation to ${attendees}ze  invitee(s). Please confirm.`,
            [HOTEL_CONF]: (attendees: string) =>
                `ze You are sending hotel confirmation emails to ${attendees}ze  invitee(s). Please confirm.`,
            [NOTE]: (attendees: string) =>
                `ze You are sending surveys/notes to ${attendees}ze  invitee(s). Please confirm.`,
            [SURVEY]: (attendees: string) =>
                `ze You are sending surveys/notes to ${attendees}ze  invitee(s). Please confirm.`,
        },
        confirmation: `ze Confirmation`,
        successfullyRegisteredForEvent: `ze Successfully registered for event.`,
        formValidationMessage: `ze Please fill out first name, last name and a valid email.`,
        registerForEvent: `ze Register for Event`,
        when: `ze When`,
        time: `ze Time`,
        city: `ze City`,
        address: `ze Address`,
        failedToLoadEventRegistrationInfo: `ze Failed to load event registration info`,
        category: {
            eventRegistration: `ze Event registration`,
            invites: `ze Invites`,
            surveyNotes: `ze Surveys / Notes`,
            hotelConf: `ze Hotel Confirmation Email`,
        },
        validationError: {
            preferredRoomType: `ze Preferred room type is required.`,
        },
        noInvitesCreatedYet: `ze No Invites Created Yet`,
        noCommunicationsCreatedYet: (parcelType: string) => `ze No ${parcelType}ze  Created Yet`,
        invitesDescription: `ze Craft personalized invitations and manager your guest list effortlessly. You can create multiple invites per event, gather essential RSVP information, and even include custom questions to tailor your event to each group's needs.`,
    },
    questions: {
        dietaryNeeds: `ze Dietary Needs`,
        checkInOutDates: `ze Check-in/Check-out Dates`,
        logisticsTypes: `ze Itinerary/Travel Notes`,
        attendanceType: `ze Attendance Type`,
        linearScale: `ze Linear Scale`,
        textBox: `ze Text Box`,
        dateSelector: `ze Date Selector`,
        singleSelect: `ze Multiple Choice - Single Select (radio button)`,
        multiSelect: `ze Multiple Choice - Multi Select (checkbox)`,
        question: `ze Question`,
        questions: `ze Questions`,
        questionPlaceholder: `ze Enter question text`,
        optionPlaceholder: `ze Enter option`,
        addOption: `ze Add Option`,
        needDietaryRestriction: `ze Do you have any dietary restrictions?`,
        howToAttend: `ze How do you plan to attend?`,
        addQuestion: `ze Add question`,
        addSurveyQuestions: `ze Add Survey Questions`,
        addSurveyQuestionsDescription: `ze Add customized questions to collect information from your attendees. Responses are only visible to you and collaborators.`,
        surveyTitle: `ze Survey Title`,
        responseDescription: `ze To view individual survey responses select view next to each recipient.`,
        status: `ze Status`,
        name: `ze Name`,
        email: `ze Email`,
    },
    virtual: {
        headline: `ze Video Conferencing and Webinars`,
        selected: `ze Selected`,
        preferred: `ze Preferred`,
        addToMeeting: `ze Add to your meeting`,
        shareDescription: `ze You'll be able to share this with attendees in invites.`,
        addConfirmation: `ze Adding this virtual meeting vendor will remove any others you have added.`,
        removeConfirmation: (name: string) => `ze Are you sure you want to remove ${name}ze  from your event?`,
        error: {
            linkRequired: `ze A link is required for a virtual meeting`,
            linkProtocol: `ze Make sure the link includes the protocol (http or https)`,
        },
    },
    scheduledForm: {
        attachDocuments: `ze Attach Documents`,
        addDocument: `ze Add Document`,
        selectColor: `ze Select a color`,
        agendaPlaceholder: `ze Ex: Introductions`,
        duration: `ze Duration`,
        descriptionPlaceholder: `ze Include any description or additional information`,
    },
    guests: {
        removeConfirmation: (email: string) => `${email}ze  was removed`,
        updateConfirmation: (email: string) => `${email}ze  was updated`,
        removeMultipleGuests: `ze Are you sure you want to delete these attendees from your meeting? If a communication was already sent, please reach out to them with any guidance.`,
        removeAttendingGuestPrompt: `ze We’ll remove this person from your meeting, along with any details you or they have entered. They’ve RSVPed as ‘Attending’, so we’ll notify them.`,
        removeNotAttendingGuestPrompt: `ze We’ll remove this person from your meeting, along with any details you or they have entered. They haven’t RSVPed as ‘Attending’, so we won’t notify them.`,
        total: `ze Total`,
        notSent: `ze Not Sent`,
        invited: `ze Invited`,
        notInvited: `ze Not Invited`,
        attending: `ze Attending`,
        declined: `ze Declined`,
        roomBlocks: `ze Rooms`,
        clickable: `ze Click here to see more details`,
        addPeople: `ze Add people`,
        addPeopleHelper: `ze Add people to your meeting by importing a list.`,
        addPeopleByEmail: `ze Add one or more people to your meeting by entering their email(s).`,
        addMultiplePeople: `ze Add multiple people by adding a comma between each email.`,
        emailsPlaceholder: `ze Enter email(s) and press enter`,
        addByEmail: `ze Add by email`,
        importList: `ze Import list`,
        subHeading: `ze Create your all-star guest list, and keep track of your attendees’ details and preferences.`,
        exportList: `ze Export list`,
        guest: `ze Guest`,
        overnightAccommodationRequired: `ze Overnight accommodation required.`,
        noOvernightAccommodationRequired: `ze No overnight accommodation required.`,
        addNoteAboutAttendee: `ze Add a note about this attendee.`,
        attendeesAdded: (count: number) => `${count}ze  attendee(s) added`,
        uploadCSVOrXLS: `ze Upload a CSV or XLS file`,
        fieldRequirements: `ze You can then select and import the following fields`,
        selectFile: `ze Select File`,
        needHelp: `ze Need help?`,
        uploadPrompt: `ze Upload your list to begin.`,
        matchColumns: `ze Match columns`,
        matchColumnDescription: `ze Now, match the columns in your file to the fields on your guest list.`,
        matchFieldRequirements: `ze Email is required; all other fields are optional.`,
        uploadFields: {
            email: `ze email`,
            required: `ze required`,
            firstName: `ze first name`,
            lastName: `ze last name`,
            phone: `ze phone`,
            id: `ze ID`,
            hotelConfirmationNumber: `ze Hotel confirmation number`,
        },
        personAdded: `ze 1 person was added`,
        peopleAdded: (count: number) => `${count}ze  people were added`,
        duplicateEmailsHelper: (count: number) =>
            `${count}ze  email(s) were duplicates and were not added to the list.`,
        singleInvalidEmail: (email: string) => `${email}ze  is not a valid email`,
        multipleInvalidEmails: (emails: string) => `${emails}ze  are not valid emails`,
        addPersonMessage: `ze 1 person will be added`,
        addPeopleMessage: (count: number) => `${count}ze  people will be added`,
        error: {
            noAttendeesFound: `ze No attendees available for export`,
            removeAttendees: (eventId: string) => `ze Could not remove attendees from meeting ${eventId}`,
            removeAttendee: (id: string, eventId: string) =>
                `ze Could not remove attendee ${id}ze  from meeting ${eventId}`,
            updateAttendee: (email: string) => `ze Could not save attendee data for ${email}`,
        },
    },
    privacyPolicy: {
        // the following is broken into pieces because "Terms and Conditions" and "Privacy Policy" need to be turned into links when rendered
        iAccept: (prefix: string) => `${prefix}ze , I accept the `,
        termsAndConditions: `ze Terms and Conditions `,
        andConfirm: `ze and confirm that I have read the `,
        privacyPolicy: `ze Privacy Policy `,
    },
    reporting: {
        header: (name: string) => `ze Here's your report, ${name}ze !`,
        headerNoName: `ze Here's your report!`,
        selectDateRange: `ze Select date range`,
        exportAllMeetings: `ze Export All Meetings`,
        exportAllBookings: `ze Export All Bookings`,
        exportAllInquiries: `ze Export All Venue Inquiries`,
        exportPreferredVenues: `ze Export Preferred Venues`,
        exportActiveUsers: `ze Export Active Users`,
        meetings: `ze Meetings`,
        // sections here are named to match the data sets in the reporting components for clarity
        preferredVenues: {
            title: `ze PREFERRED VENUE USAGE`,
            tooltip: `ze The Preferred Venue Usage graphic displays the total spend on preferred and non-preferred venues as well as cumulative total spend, segmented by Hotel vs all other venue types.`,
            total: `ze Total`,
            preferredVenue: `ze Preferred Venue`,
            nonPreferred: `ze Non-preferred`,
        },
        attendeesStats: {
            title: `ze TOTAL ATTENDANCE`,
            tooltip: `ze The Total Attendance graphic displays the number of people invited to a meeting and the total number of people who attended the meeting.`,
            invited: `ze Invited`,
            attended: `ze Attended`,
        },
        userStatistics: {
            title: `ze USER STATISTICS`,
            tooltip: `ze The User Statistics graphic displays how many users are on your team, and how many user have logged in, accepted a proposal, and uploaded a contract for a booked event over the given time period.`,
            totalUsers: `ze Total Users`,
            active: `ze Active`,
            accepted: `ze Accepted`,
            booked: `ze Booked`,
        },
        meetingsStats: {
            title: `ze MEETINGS VOLUME`,
            tooltip: `ze The Meetings Volume graphic displays how many events were created, how many had inquiries sent out, how many received proposals, how many were booked, and how many were cancelled.`,
            total: `ze Total`,
            inquired: `ze Inquired`,
            accepted: `ze Accepted`,
            booked: `ze Booked`,
            cancelled: `ze Cancelled`,
        },
        memberStats: {
            title: `ze MEMBER STATS`,
            totalMembers: `ze Total Members`,
            inquiriesSent: `ze Inquiries Sent`,
            proposalsAccepted: `ze Proposals Accepted`,
            contractsUploaded: `ze Contracts Uploaded`,
        },
        totalSpend: {
            title: `ze TOTAL SPEND`,
            tooltip: `ze The Total Spend graph is a cumulative graph displaying the total cumulative spend to date, delineated between preferred venues in green and the total spent on all venues in grey.`,
            totalSpend: `ze Total Spend`,
            preferredVenues: `ze Preferred Venues`,
            date: `ze Date`,
        },
        activeVenues: {
            title: `ze TOTAL VOLUME SEGMENTATION`,
            tooltip: `ze The Total Volume Segmentation graphic displays the percentage of the total amount spent per venue type.`,
        },
    },
    // FORM FIELDS
    authFields: {
        email: `ze Email`,
        emailPlaceholder: `ze name@company.com`,
        sendLink: `ze Send Link`,
        verifyEmail: `ze Verify Email`,
        companyName: `ze Company Name`,
        firstName: `ze First Name`,
        lastName: `ze Last Name`,
        phone: `ze Phone`,
        phoneNumber: `ze Phone Number`,
        jobTitle: `ze Job Title`,
        department: `ze Department`,
        or: `ze or`,
        signInWithGoogle: `ze Sign in with Google`,
        signUpWithGoogle: `ze Sign up with Google`,
        placeholder: (field: string) => `ze Enter ${field}`,
        sales: `ze Sales`,
        marketing: `ze Marketing`,
        engineering: `ze Engineering`,
        businessDevelopment: `ze Business Development`,
        hr: `ze HR`,
        other: `ze Other`,
        salesMarketing: `ze Sales & Marketing`,
        cateringFoodBeverage: `ze Catering / Food & Beverage`,
        operations: `ze Operations`,
        guestServices: `ze Guest Services`,
    },
    //POPUPS
    subscription: {
        needAccess: `ze Need access to more features?`,
        sorryHeader: `ze Sorry, not right now.`,
        upgradeAccount: `ze Upgrade your team account to:`,
        noAccess: `ze Your team does not allow access to these features.`,
        upgradeToPro: `ze Upgrade to Pro`,
        upgradeToEnterprise: `ze Upgrade to Enterprise`,
        proPerk1: `ze Schedule an unlimited number of events`,
        proPerk2: `ze Create custom templates to fit your team's needs`,
        proPerk3: `ze Set a custom logo and color to match your brand`,
        enterprisePerk1: `ze Access in-depth reporting data`,
        enterprisePerk2: `ze Integrate Bizly with your Single-Sign On system`,
        enterprisePerk3: `ze Custom-configure team controls and partner integrations`,
        notRightNow: `ze Not Right Now`,
    },
    // GENERICS
    button: {
        accept: `ze Accept`,
        add: `ze Add`,
        apply: `ze Apply`,
        back: `ze Back`,
        cancel: `ze Cancel`,
        cancelEvent: `ze Cancel Event`,
        confirm: `ze Confirm`,
        continue: `ze Continue`,
        copy: `ze Copy`,
        copyUrl: `ze Copy URL`,
        clear: `ze Clear`,
        create: `ze Create`,
        createEvent: `ze Create Event`,
        defaultLiteral: `ze Default`,
        delete: `ze Delete`,
        dismiss: `ze Dismiss`,
        download: `ze Download`,
        edit: `ze Edit`,
        export: `ze Export`,
        generateWithAI: `ze Generate with AI`,
        import: `ze Import`,
        invite: `ze Invite`,
        launch: `ze Launch`,
        manage: `ze Manage`,
        next: `ze Next`,
        no: `ze No`,
        notRightNow: `ze Not Right Now`,
        preview: `ze Preview`,
        previous: `ze Previous`,
        proceed: `ze Proceed`,
        readMore: `ze Read more`,
        reload: `ze Reload`,
        reject: `ze Reject`,
        remove: `ze Remove`,
        replace: `ze Replace`,
        request: `ze Request`,
        reset: `ze Reset`,
        register: `ze Register`,
        resubmit: `ze Resubmit`,
        save: `ze Save`,
        select: `ze Select`,
        selectFile: `ze Select File`,
        sendMessage: `ze Send Message`,
        skip: `ze Skip`,
        messageVenue: `ze Message Venue`,
        saveChanges: `ze Save Changes`,
        search: `ze Search`,
        send: `ze Send`,
        sent: `ze Sent`,
        set: `ze Set`,
        submit: `ze Submit`,
        update: `ze Update`,
        upload: `ze Upload`,
        view: `ze View`,
        viewAll: `ze View All`,
        viewCard: `ze View Card`,
        yes: `ze Yes`,
        aiAssist: `ze AI Assist`,
        uploadCsv: `ze Upload CSV`,
        replaceCsv: `ze Replace CSV`,
    },
    modal: {
        areYouSure: `ze Are you sure?`,
        unsavedPrompt: `ze You have unsaved changes, are you sure you want to leave?`,
        timeZone: `ze Meeting Time Zone`,
        timeZoneDescription: `ze Updating the time zone will affect all times for this meeting.`,
        selectTimezone: `ze Select a Time Zone`,
        editDetails: `ze Edit Details`,
        responded: `ze Responded`,
        pending: `ze Pending`,
        responses: `ze Responses`,
    },
    error: {
        default: `ze Something went wrong. Please try again.`,
        submission: `ze Error attempting to submit data.`,
        contactSupport: `ze Please contact support.`,
        contactOrRefreshPage: `ze Please contact support or try refreshing the page.`,
        contactOrRevisit: `ze Please contact support or try visiting the link in your email again.`,
        somethingWentWrong: `ze Something went wrong.`,
        fillAll: `ze Please fill out all fields.`,
        inquirySubmission: `ze Oops! We weren't able to submit your inquiry. Please try again.`,
        inquiryExist: `ze Meeting already has an inquiry.`,
        inquiryEditSubmitted: `ze Can only edit unsubmitted inquiries`,
        copyUnsubmittedInquiry: `ze Can only copy a submitted inquiry`,
        eventProvider: `ze useEvent must be used within a EventProvider`,
        cannotSendMessage: `ze Your message couldn't be sent. Try again.`,
        submitData: `ze Error attempting to submit data`,
        tooLargeFile: (fileName: string) => `ze File ${fileName}ze  is too large.`,
        refreshAndTry: `ze Please refresh and try again.`,
        loadQuestion: `ze We were unable to retrieve your questions. Please refresh and try again.`,
        deleteQuestion: `ze An error occurred while deleting your question. Please refresh and try again.`,
        saveQuestion: `ze An error occurred while saving your data. Please try again.`,
        fetchMeetingData: (eventId: string) => `ze Could not fetch data for meeting ${eventId}`,
        validPhone: `ze Please enter a valid phone number`,
        tryAgain: `ze Please try again.`,
        desktopOnlyMessage: `ze For better experience, please use desktop.`,
        maxFilesExceeded: `ze Max upload number exceeded. You can upload only two files.`,
    },
    common: {
        inputPlaceholder: `ze Enter text here.`,
        dayIndex: (index: number) => `ze Day ${index}`,
        guestNumberFormatter: (guests: number) => `ze Room Nights ${guests}`,
        guestRoomNeeded: `ze Guest rooms needed`,
        meetingSpaceIndex: (index: number) => `ze Meeting Space ${index}`,
        copyMeetingSpace: (copyCount: number) => `ze Copy (${copyCount}ze )`,
        guestRoomsIndex: (index: number) => `ze Guest Rooms ${index}`,
        guestFormatter: (count: number) => `${count}ze  ${count === 1 ? `ze Guest` : `ze Guests`}`,
        imageAlt: (image: string) => `ze image of ${image}`,
        brand: `ze Brand`,
        maxGuestFormatter: (count: number) => `${count}ze  Guest Max`,
        spacesFormatter: (count: number) => `${count}ze  Spaces`,
        teamLogoAlt: `ze team-logo`,
        attachments: `ze Attachments`,
        unknown: `ze Unknown`,
        start: `ze Start`,
        end: `ze End`,
        //time-related prepositions
        at: `ze at`,
        // example: 'You sent a request at 3:00pm`
        on: `ze on`,
        // example: 'You sent a request on December 15'
        agenda: `ze agenda`,
        inquiry: `ze inquiry`,
        booking: `ze booking`,
        concatWords: (...parmas: string[]) => parmas.join(`ze  and `),
        required: `ze Required`,
        optionIndex: (index: number) => `ze Option ${index}`,
        noNotes: `ze No notes`,
        accommodation: `ze Accommodation`,
        hotelConfirmationNumber: `ze Hotel confirmation number`,
        error: `ze Error`,
        time: `ze Time`,
        sort: `ze Sort`,
        period: `ze .`,
        file: `ze File`,
        emptyValue: `ze N/A`,
    },
    currency: {
        CADLabel: `ze CAD (Canadian dollar)`,
        EURLabel: `ze EUR (Euro)`,
        GBPLabel: `ze GBP (pound sterling)`,
        MXNLabel: `ze MXN (Mexican peso)`,
        USDLabel: `ze USD (United States dollar)`,
        DKKLabel: `ze DKK (Danish Krone)`,
        ISKLabel: `ze ISK (Icelandic Krona)`,
        SEKLabel: `ze SEK (Swedish Krone)`,
        NOKLabel: `ze NOK (Norwegian Krone)`,
    },
    datetime: {
        datesCalculated: (info: string) => `ze Dates calculated using ${info}ze .`,
        calculationTimeInfo: `ze Guestrooms are given a time of 12:00 PM for calculations.`,
        startDate: `ze Start Date`,
        date: `ze Date`,
        endDate: `ze End Date`,
        startTime: `ze Start Time`,
        endTime: `ze End Time`,
        checkInDate: `ze Check-in Date`,
        checkOutDate: `ze Check-out Date`,
    },
    proTip: {
        note: `ze Note`,
        proTip: `ze Pro Tip`,
        proTips: `ze Pro Tips`,
    },
    notFound: {
        header: `ze Page not found!`,
        subHeader: `ze This page may have been moved or deleted. Please check the URL is correct in the address bar, and get in touch with us if you continue to have issues.`,
        redirectionInSeconds: (seconds: number) =>
            `ze You will be redirected in ${seconds}ze  ${seconds > 1 ? `ze seconds` : `ze second`}`,
        backToHome: `ze Back to Home`,
    },
    // API - these labels are designed to work with the API type declarations in src/api/*Api.ts
    api: {
        eventOptions: {
            avOptions: {
                internet: `ze Internet`,
                audioconferencing: `ze Audioconferencing`,
                videoconferencing: `ze Videoconferencing`,
                projectorScreen: `ze Projector & Screen`,
                flipChart: `ze Flip Chart`,
                flatScreen: `ze Flat Screen`,
            },
            fbOptions: {
                breakfast: `ze Breakfast`,
                lunch: `ze Lunch`,
                dinner: `ze Dinner`,
                morningBreak: `ze Morning Break`,
                afternoonBreak: `ze Afternoon Break`,
                reception: `ze Reception`,
            },
            diningStyles: {
                buffet: `ze Buffet`,
                plated: `ze Plated`,
            },
            setupOptions: {
                banquetRounds: `ze Banquet Rounds`,
                conference: `ze Conference`,
                classroom: `ze Classroom`,
                crescentRounds: `ze Crescent Rounds`,
                hollowSquare: `ze Hollow Square`,
                reception: `ze Reception`,
                theater: `ze Theater`,
                uShape: `ze U-Shape`,
                pods: `ze Pods`,
            },
            dietaryRestrictionOptions: {
                vegan: `ze Vegan`,
                veganDescription: `ze A plant based diet with no animal products. They do not eat meat, fish, eggs or dairy products.`,
                vegetarian: `ze Vegetarian`,
                vegetarianDescription: `ze Eat mostly plants.`,
                kosher: `ze Kosher`,
                kosherDescription: `ze Keep it Kosher`,
                halal: `ze Halal`,
                halalDescription: `ze Halal`,
                pescatarian: `ze Pescatarian`,
                pescatarianDescription: `ze Vegetarian diet but they also eat fish. They do not eat meat.`,
                glutenFree: `ze Gluten Free`,
                glutenFreeDescription: `ze Gluten Free`,
            },
            foodAllergyOptions: {
                dairyAllergy: `ze Dairy Allergy`,
                dairyAllergyDescription: `ze Milk & dairy products`,
                eggAllergy: `ze Egg Allergy`,
                eggAllergyDescription: `ze Eggs & egg products`,
                peanutAllergy: `ze Peanut Allergy`,
                peanutAllergyDescription: `ze Peanut allergies`,
                treeNutAllergy: `ze Tree Nut Allergy`,
                treeNutAllergyDescription: `ze Tree nuts, like walnuts, almonds, pine nuts, brazil nuts, and pecans`,
                soyAllergy: `ze Soy Allergy`,
                soyAllergyDescription: `ze Soy & soy products`,
                wheatAllergy: `ze Wheat Allergy`,
                wheatAllergyDescription: `ze Wheat & other grains with gluten, including barley, rye, and oats`,
                fishAllergy: `ze Fish Allergy`,
                fishAllergyDescription: `ze Fish allergies`,
                shellfishAllergy: `ze Shellfish Allergy`,
                shellfishAllergyDescription: `ze Shellfish allergies`,
            },
        },
    },
};
