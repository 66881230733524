import Grid from '@material-ui/core/Grid';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { SpinnerOverlay } from 'components/Spinner';
import { Spacer } from 'components/Ui-V2/Spacer/Spacer';
import { REJECTED_STATUSES } from 'components/VenueCard';
import { sortGalleryInquiries } from 'components/VenueInquiry/utils';
import DashboardVenueTile from 'components/VenueTile/DashboardVenueTile';
import { Headline, IconHeadline } from 'components/ui/Headline';
import { useGetVenueInquiries } from 'hooks/queries/useQueries';
import VenuePinIconSVG from 'images/icons/venue_pin.svg?react';
import { useMemo } from 'react';
import { withIconStyles } from 'shared';
import { useCurrentInquiry } from 'stores/current-inquiry';
import { EColors, getColor } from 'theme';
import { i18n } from 'translation';
import { AlignedRow, Button, Column } from 'ui';
import { VenueTiles } from './VenueTiles';
import { DEFAULT, HAS_BOOKED, HAS_INQUIRIES, NO_CITY, TBookedStatus } from './utils';

const VenuePinIcon = withIconStyles(VenuePinIconSVG);

const CardColumn = styled(Column)(() => ({
    flexGrow: 1,
    flexShrink: 0,
    flexBasis: 0,
}));

const SectionTitle = styled(Headline)(() => ({
    marginTop: 16,
    marginBottom: 16,
    fontWeight: 500,
    color: getColor(EColors.totalAttendee),
}));

const GridContainer = styled(Grid)(() => ({
    gap: '16px',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridAutoRows: '1fr',

    '&.MuiGrid-container': {
        display: 'grid',
    },
}));

const StyledButton = styled(Button)(() => ({
    '&:hover': {
        color: getColor(EColors.pureWhite),
    },
}));

const VenueSection = ({
    suggestedVenues,
    eventId,
    location,
    viewVenueListing,
    viewProposal,
    onSearch,
    onEditInquiries,
}: Partial<Bizly.Event> & {
    eventId: number;
    eventName: string;
    suggestedVenues: Bizly.Venue[];
    viewVenueListing: (venueId: number) => void;
    viewProposal: (venue?: Bizly.Venue) => void;
    onSearch: () => void;
    onEditInquiries: () => void;
}) => {
    const { data: inquiries, isLoading: loadingInquiries } = useGetVenueInquiries(eventId);
    const bookedInquiry = inquiries?.find(inquiry => inquiry.booked);

    let bookedState: TBookedStatus;

    const hasInquiryVenues = inquiries && inquiries.some(inquiry => inquiry.venue && inquiry.status !== 'Added');
    if (hasInquiryVenues) {
        bookedState = bookedInquiry ? HAS_BOOKED : HAS_INQUIRIES;
    } else {
        bookedState = location ? DEFAULT : NO_CITY;
    }

    const venues = hasInquiryVenues
        ? inquiries?.map(inquiry => ({ status: inquiry.status, ...inquiry.venue }))
        : location
          ? suggestedVenues
          : [];

    const { venues: inquiryVenues } = useCurrentInquiry();
    const validVenues = useMemo(
        () => (inquiryVenues || [])?.filter(venue => !REJECTED_STATUSES.has(venue.status)),
        [inquiryVenues]
    );
    const noSelectedVenues = validVenues.length === 0;

    const VenueCard = () =>
        bookedInquiry ? (
            <DashboardVenueTile
                key={bookedInquiry.venue.id}
                venue={{ ...bookedInquiry.venue, status: bookedInquiry.status }}
                format="proposalThumb"
                pillType="inquiryStatus"
                fixedRatio="56%"
                width="100%"
                onClick={viewVenueListing}
            />
        ) : (
            venues && (
                <VenueTiles
                    venues={sortGalleryInquiries(noSelectedVenues ? venues : validVenues) || []}
                    bookedState={bookedState}
                    viewVenueListing={viewVenueListing}
                />
            )
        );

    const VenueSectionTitle = () => {
        return (
            <SectionTitle thin>
                {noSelectedVenues
                    ? i18n.meetingDashboard.venueSection.recommendedVenues
                    : i18n.meetingDashboard.venueSection.venues}
            </SectionTitle>
        );
    };

    if (loadingInquiries) {
        return (
            <Column>
                <IconHeadline icon={<VenuePinIcon />} headline="Venue" description="" />
                <SpinnerOverlay transparent />
            </Column>
        );
    }

    return (
        <CardColumn>
            <AlignedRow justifyContent="space-between">
                <VenueSectionTitle />
            </AlignedRow>

            <GridContainer container justifyContent="center">
                <VenueCard />
            </GridContainer>

            <Spacer medium />

            <Box>
                {bookedState === HAS_BOOKED ? (
                    <StyledButton fullWidth variant="outlined" onClick={() => viewProposal(bookedInquiry)}>
                        {i18n.venue.inquiry.viewProposalDetails}
                    </StyledButton>
                ) : bookedState === HAS_INQUIRIES ? (
                    <StyledButton fullWidth variant="outlined" onClick={() => onEditInquiries()}>
                        {i18n.venue.inquiry.viewInquiryDetails}
                    </StyledButton>
                ) : (
                    <StyledButton fullWidth variant="outlined" onClick={() => onSearch()}>
                        {i18n.meetingDashboard.venueSection.exploreMoreVenues}
                    </StyledButton>
                )}
            </Box>
        </CardColumn>
    );
};

export default VenueSection;
