import { Box } from '@mui/material';
import { InquiryDetailsType } from 'components/InquiryDetailsModal';
import { Button } from 'components/Ui-V2/Button/Button';
import { TMenuOption, VenueCardPopover } from 'components/VenueCard';
import { TabLabels } from 'constants/venueStatus';
import styled from 'styled-components';
import { i18n } from 'translation';
import { EColors } from 'types/themenum';
import { HEADER_HEIGHT } from 'utils/header';
import { tzMoment } from 'utils/moment';
import { BaseVenueTile, VenueTileProps } from './VenueTile';

const HOURS_FOR_NUDGE = 24;

type InquiryVenue = BizlyAPI.Inquiry.Venue & { submittedAt?: string; nudgedAt?: string | null }

// Helper function to determine whether to show or disable nudge.
function getNudgeStatus(inquiryVenue?: InquiryVenue | null) {
    // Default states
    let showNudge = false;
    let nudgeDisabled = false;
    let shouldRowReverse = false;

    if (inquiryVenue) {
        const submittedAt = tzMoment(inquiryVenue?.submittedAt);
        // Show nudge if we've passed 24 hours since submission.
        // If submittedAt + 24 hours is BEFORE now, it means at least 24 hours have passed.
        const hasExceeded24HoursSinceSubmission = submittedAt.add(HOURS_FOR_NUDGE, 'h').isBefore(tzMoment());
        if (hasExceeded24HoursSinceSubmission) {
            showNudge = true;
        }

        const lastNudgedAt = tzMoment(inquiryVenue.nudgedAt ?? inquiryVenue.submittedAt);
        // Nudge is disabled if it has NOT yet been 24 hours since last nudge (or submission if never nudged).
        const hasItBeen24HoursSinceLastNudge = lastNudgedAt.add(HOURS_FOR_NUDGE, 'h').isBefore(tzMoment());
        if (!hasItBeen24HoursSinceLastNudge) {
            nudgeDisabled = true;
        }

        shouldRowReverse = showNudge;
    }

    return { showNudge, nudgeDisabled, shouldRowReverse };
}

const TileWrapper = styled.div`
    border-radius: 8px;
    border: 1px solid;
    border-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
    padding: 8px;
    height: 100%;

    position: sticky;
    background: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    top: ${HEADER_HEIGHT}px;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const TileButtons = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 8px;
    flex-direction: column;

    @media (min-width: 1490px) {
        flex-direction: row;
    }

    & > .MuiButton-root:only-child {
        background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.primaryAction)};
        color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};
    }

    & > .MuiButton-root {
        margin-right: 0;
        flex: 1;
        &.MuiButton-containedPrimary {
            margin-right: 0;
        }
    }
`;

const StyledPopOverContainer = styled(Button)`
    &.MuiButton-root {
        display: flex;
        &:hover {
            background-color: transparent;
        }
    }
`;

const StyledInquiryButton = styled(Button)`
    &.MuiButton-root {
        flex: 1;
    }
`;

const StyledButton = styled(Button)`
    &.MuiButton-root {
        flex: 1;
        margin-right: 0;
    }
`;

const ActionButtonWrapper = styled(Box)`
    display: flex;
    flex: 1;
    justify-content: center;

    @media (min-width: 1490px) {
        justify-content: flex-end;
    }
`;

type TInquiryButton = { label: string; handler: () => void };

type TActions = {
    tabLabel: TabLabels;
    inquiryVenue?: InquiryVenue;
    viewInquiry: () => void;
    onNudge?: () => void;
    inquiryButton?: TInquiryButton;
    onCancelInquiry?: (venueId: number, venueInquiryId: number) => void;
    directCancelInquiry?: () => void;
    inquiryDetails?: InquiryDetailsType;
};

type InquiryVenueTileProps = VenueTileProps & TActions;

export const InquiryVenueTile = (props: InquiryVenueTileProps) => {
    const { inquiryVenue, viewInquiry, onNudge, inquiryButton, onCancelInquiry, directCancelInquiry, ...tileProps } =
        props;

    // If the venue is 'Submitted', compute nudge states
    const { showNudge, nudgeDisabled, shouldRowReverse } =
        tileProps.venue?.status === 'Submitted'
            ? getNudgeStatus(inquiryVenue)
            : {
                  showNudge: false,
                  nudgeDisabled: false,
                  shouldRowReverse: false,
              };

    const renderInquiryActionButton = () => {
        if (!inquiryVenue) return null;
        const venueStatus = tileProps?.venue?.status;

        if (venueStatus === 'Submitted') {
            const menuOptions: TMenuOption[] = [
                {
                    label: i18n.venue.inquiry.nudge,
                    disabled: nudgeDisabled || !showNudge,
                    handler: () => onNudge?.(),
                },
                {
                    label: i18n.venue.inquiry.cancelInquiry,
                    handler: () => {
                        if (inquiryVenue.venueInquiryId) {
                            directCancelInquiry?.();
                        }
                    },
                },
            ];

            return (
                <StyledPopOverContainer variant="text">
                    <VenueCardPopover color={EColors.pureBlack} options={menuOptions} />
                </StyledPopOverContainer>
            );
        }

        // If the venue is not 'Submitted', we fall back to the provided inquiryButton.
        return (
            <StyledButton variant="contained" onClick={inquiryButton?.handler}>
                {inquiryButton?.label}
            </StyledButton>
        );
    };

    return (
        <TileWrapper className="sticky">
            <BaseVenueTile {...tileProps} hideButtons />
            <TileButtons>
                <StyledInquiryButton variant={shouldRowReverse ? 'contained' : 'outlined'} onClick={viewInquiry}>
                    {i18n.venue.inquiry.viewInquiry}
                </StyledInquiryButton>

                {inquiryButton && <ActionButtonWrapper>{renderInquiryActionButton()}</ActionButtonWrapper>}
            </TileButtons>
        </TileWrapper>
    );
};
