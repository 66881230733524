import { isEmpty, keyBy, mapValues } from 'lodash';
import debounce from 'lodash/debounce';
import { INVITE, REGISTRATION_PAGE } from 'pages/EditParcel/utils';
import React from 'react';
import { tzMoment } from 'utils/moment';

export const padArray = <T, U = undefined>(arr: T[], len: number, fill?: U): (T | U)[] =>
    arr.length > len ? arr : arr.concat(Array(len).fill(fill)).slice(0, len);

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const legacyHostName =
    import.meta.env.NODE_ENV === 'production' ? 'https://app.bizly.com/' : 'https://app-dev.bizly.com/';

export const goToLegacyApp = (path: string) => {
    const win = window.open(`${legacyHostName}${path || 'dashboard/events'}`, '_blank');
    win && win.focus();
};

export const downloadFromUrl = (url: string) => {
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', url);
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const getFirstMatchingKey = <T extends object, U extends object>(obj1: T, obj2: U) => {
    for (const key of Object.keys(obj1)) {
        if (obj2[key as keyof U] !== null) {
            return key;
        }
    }

    return 'default';
};

export const matchLastOrDefault = <TValue>(
    valuesDict: { [key: string]: TValue },
    props: Record<keyof typeof valuesDict, boolean | undefined> & { [key: string]: any }
): TValue =>
    valuesDict[
        Object.keys(props)
            .reverse()
            .filter(key => props[key] === true && valuesDict[key] !== undefined)[0]
    ] || valuesDict.default;

export const randomSelect = <T>(arr: T[] = []) => arr[Math.floor(Math.random() * arr.length)];

export const parseName = (copy = '', name?: string) => (name ? copy.replace('%N', name) : copy);

const emailRegEx = /^[+a-zA-Z0-9_.!#$%&'*/=?^`{|}~-]+@([a-zA-Z0-9-]+\.)+[a-zA-Z0-9]{2,63}$/;

export const emailIsValid = (email: string) => emailRegEx.test(email);
export const parcelType = (parcel: Bizly.Parcel) => {
    if ((parcel.traits && Object.keys(parcel.traits).includes('public')) || parcel.type?.includes('registration')) {
        return REGISTRATION_PAGE;
    }

    if (parcel.traits && !Array.isArray(parcel.traits) && Object.keys(parcel.traits).includes('rsvp')) {
        return INVITE;
    }

    return parcel.type;
};

export const formatCurrency = (
    currency: Nullable<number>,
    currencyCode = 'USD',
    withCents: 'as-needed' | 'always' | undefined = 'as-needed'
): string => {
    if (currency || currency === 0) {
        const result = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: currencyCode,
            ...(!withCents ? { maximumFractionDigits: 0 } : {}),
        }).format(currency);

        if (withCents) {
            const [whole, fraction] = result.split('.'); // TODO: refactor if not using 'en-US' because it'll be $100,00
            if (withCents === 'as-needed' && parseInt(fraction) === 0) {
                return whole;
            }
        }

        return result;
    } else {
        return '0';
    }
};

export const formatPercentage = (percentage: Nullable<number>): string =>
    percentage ? percentage.toString() + '%' : '0%';

export const formatDate = (dateAsString: string) => tzMoment(dateAsString).format('LT');

const METERS_PER_MILE = 1609.34;
const KILOMETERS_PER_MILE = 1.60934;
const KILOMETERS_PER_METER = 1000;

export function toMeters(miles: Distance.Mile): Distance.Meter {
    return Math.ceil(miles * METERS_PER_MILE) as Distance.Meter;
}

export function toMiles(meters: Distance.Meter): Distance.Mile {
    return Math.floor(meters / METERS_PER_MILE) as Distance.Mile;
}

export function kmToMi(km: Distance.Kilometer): Distance.Mile {
    return Math.floor(km / KILOMETERS_PER_MILE) as Distance.Mile;
}

export function miToKm(miles: Distance.Mile): Distance.Kilometer {
    return Math.ceil(miles * KILOMETERS_PER_MILE) as Distance.Kilometer;
}

export function mToKm(meters: Distance.Meter): Distance.Kilometer {
    return Math.floor(meters / KILOMETERS_PER_METER) as Distance.Kilometer;
}

export function kmToM(kilometers: Distance.Kilometer): Distance.Meter {
    return Math.ceil(kilometers * KILOMETERS_PER_METER) as Distance.Meter;
}

export const takeFirstIfArray = (params: { [param: string]: string | string[] | null | undefined }) =>
    Object.keys(params).reduce(
        (agg, param) => ({
            ...agg,
            [param]: Array.isArray(params[param] as string[]) ? params[param]![0] : params[param],
        }),
        params
    ) as { [param: string]: string | null | undefined };

export const fillObject = <TKey extends string, TFill extends any>(keys: TKey[], fill: TFill) =>
    mapValues(keyBy(keys) as Record<TKey, TKey>, () => fill);

/*
    Debouncing is an async activity outside the React lifecycle
    Therefore, it is vulnerable to race conditions

    We will need to give the component a stable debounced function in which subsequent calls cancel prior calls
    The debounced function will need to call the *latest* version of the passed in callback

    Therefore, we must store the latest version of the callback
    But pass out a stable debounced function

    Limitations: changing the delay value will not update the function, this can be added as a future update
*/
export function useDebounce(callback?: (...args: any[]) => any, defaultDelay = 200) {
    const savedCallback = React.useRef(callback);
    React.useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    const trigger = React.useRef(
        debounce((...args: any[]) => {
            // the callback may have changed by the time we hit this line, so we must use the latest version
            if (savedCallback.current) {
                return savedCallback.current(...(args ?? []));
            }
        }, defaultDelay)
    );

    return [trigger.current];
}

export const enableTurnDown =
    import.meta.env.VITE_APP_ENV === 'prod'
        ? import.meta.env.VITE_APP_TURN_DOWN_PROD === 'true'
        : import.meta.env.VITE_APP_TURN_DOWN === 'true';

export const hasSpecialCancellationEmail = function (
    setting?: Bizly.Team['specialCancellationEmail']
): setting is Exclude<Bizly.Team['specialCancellationEmail'], []> {
    return !!setting && !Array.isArray(setting);
};

export function numberWithCommas(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const getLatestProposalRequest = (proposal: Bizly.VenueProposal) => {
    if (isEmpty(proposal.approvalRequests)) {
        return null;
    }
    const latestRequest = proposal.approvalRequests.reduce(
        (
            prev: Bizly.VenueProposal['approvalRequests'][number],
            current: Bizly.VenueProposal['approvalRequests'][number]
        ) => {
            return new Date(prev.updatedAt).getTime() > new Date(current.updatedAt).getTime() ? prev : current;
        },
        proposal.approvalRequests[0]
    );
    return latestRequest;
};

export const calculateIsAboveBudgetWithBuffer = (
    proposal: Bizly.VenueProposal,
    user: Bizly.User,
    event: Bizly.Event,
    budgetBufferPercentage: number,
    totalConvertedProposal: number
) => {
    const isApple = isAppleUser(user);
    const hasEventGrandTotalBudget = isApple ? Number(event.budget) : 0;

    const proposalRequest = getLatestProposalRequest(proposal);
    const proposalApprovedAmount = proposalRequest ? proposalRequest.approvedAmount : 0;

    const budgetFromMeetingLevel = hasEventGrandTotalBudget ? Number(event.budget) : 0;
    const budgetFromTeamSettings = Number(user?.team?.maximumBudget);

    // Apple or Ricoh
    const specialBudgetSource = budgetFromTeamSettings ? budgetFromTeamSettings : isApple ? budgetFromMeetingLevel : 0;

    const maxBudget = proposalApprovedAmount || specialBudgetSource || 0;

    const budgetWithBuffer = maxBudget ? maxBudget * budgetBufferPercentage : 0;

    const isAboveBudget = maxBudget ? totalConvertedProposal > budgetWithBuffer : false;
    const hasBudget =
        !!proposalApprovedAmount || hasEventGrandTotalBudget || Boolean(Number(user?.team?.maximumBudget));

    return {
        isAboveBudget,
        hasBudget,
    };
};

export const isAppleUser = (user: Bizly.User) => {
    if (!user) {
        return false;
    }
    return user.team?.id === 10982;
};

export const formatDateToShort = (dateString: string | null | undefined): string => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    return dateString ? new Date(dateString).toLocaleDateString('en-US', options) : '';
};
