import { Tooltip } from '@material-ui/core';
import { i18n } from 'translation';
import { userFormattedTimestamp } from 'utils/moment';
import { AcceptRejectButton } from './ProposalActionButton';

export const ProposalExtensionButton = ({
    proposal,
    onAccept,
    expired,
}: {
    proposal: Bizly.VenueProposal;
    onAccept: () => void;
    expired?: boolean;
}) => {
    const extRejectTimestamp = userFormattedTimestamp(proposal.extensionRejectedAt, true);
    const tooltip = proposal.extensionRejected
        ? i18n.venue.proposal.expiryExtensionRejected(
              proposal.venue.name,
              extRejectTimestamp.formattedTime,
              extRejectTimestamp.preposition
          )
        : '';

    const isDisabled = !expired || proposal.extensionRejected;

    return (
        <Tooltip title={tooltip}>
            <span>
                <AcceptRejectButton onClick={onAccept} width={150} disabled={isDisabled}>
                    {i18n.venue.inquiry.requestExtension}
                </AcceptRejectButton>
            </span>
        </Tooltip>
    );
};
