import compact from 'lodash/compact';
import partition from 'lodash/partition';
import uniq from 'lodash/uniq';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { addAttendees } from 'api';
import { MEETINGS_NAV_WIDTH } from 'components/MeetingsNav';
import { SIDE_SMALL_NAV_WIDTH } from 'components/SideNav';
import { Spinner } from 'components/Spinner';
import { StandardInput } from 'components/StandardInput';
import { StickyHeader, stickyHeaderHeight } from 'components/StickyHeader';
import { Button } from 'components/Ui-V2/Button/Button';
import { PageHeadline } from 'components/ui/Headline';
import DeleteIconSVG from 'images/icons/trash-can.svg?react';
import { contentWidth, withInteractibleIconStyles } from 'shared';
import { EColors } from 'theme';
import { i18n } from 'translation';
import { Column, Copy, Spacer, TopRounded, TruncatingSingleLineCopy } from 'ui';
import { emailIsValid } from '../../util';

const DeleteIcon = withInteractibleIconStyles(DeleteIconSVG);

const Centered = styled(Column)`
    width: ${contentWidth};
    margin: ${stickyHeaderHeight} auto;
    padding: 36px 0;
`;

type PendingAddition = {
    key: number;
    email: string;
};

export const AddAttendees = ({
    event,
    attendees,
    setAttendees,
}: {
    event: Bizly.Event;
    attendees: Array<Bizly.EventAttendee>;
    setAttendees: (attendees: Array<Bizly.EventAttendee>) => void;
}) => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [key, setKey] = React.useState(0);
    const [pending, setPending] = React.useState(false);
    const [pendingAddition, setPendingAddition] = React.useState<Array<PendingAddition>>([]);
    const [pendingInput, setPendingInput] = React.useState('');

    async function handleAddClick() {
        const { newEmailsCount, duplicateEmailsCount } = getEmailCounts(pendingAddition);

        let successMessage = newEmailsCount === 1 ? i18n.guests.personAdded : i18n.guests.peopleAdded(newEmailsCount);

        if (duplicateEmailsCount > 0) {
            successMessage += '. ' + i18n.guests.duplicateEmailsHelper(duplicateEmailsCount);
        }

        const successUrl = `/event/${event.id}/guest-list`;

        setPending(true);

        const uniquePendingAddition = uniq(pendingAddition);

        try {
            const updatedAttendees = await addAttendees(
                event.id,
                uniquePendingAddition.map(pa => ({ email: pa.email }))
            );

            setAttendees(updatedAttendees);

            enqueueSnackbar(successMessage, {
                variant: 'info',
            });
            navigate(successUrl);
        } catch (e) {
            enqueueSnackbar(`${i18n.common.error}: ${e}`, { variant: 'error' });
        }

        setPending(false);
    }

    function getPendingAdditionEmails(pendingAddition: Array<PendingAddition>) {
        return pendingAddition.map(invitee => invitee.email);
    }

    function getEmailCounts(pendingAddition: Array<PendingAddition>): {
        newEmailsCount: number;
        duplicateEmailsCount: number;
    } {
        const attendeeEmails = attendees.map((attendee: Bizly.EventAttendee) => attendee.email);

        const pendingAdditionEmails = getPendingAdditionEmails(pendingAddition);
        const uniquePendingAdditionEmails = uniq(pendingAdditionEmails);

        const [duplicateEmails, newEmails] = partition(uniquePendingAdditionEmails, email =>
            attendeeEmails.includes(email)
        );

        const newEmailsCount = newEmails.length;

        const duplicateEmailsCount =
            duplicateEmails.length + pendingAdditionEmails.length - uniquePendingAdditionEmails.length;

        return { newEmailsCount, duplicateEmailsCount };
    }

    function handlePendingAdditionsSubmit(newValue: string) {
        const emails = newValue
            .split(' ')
            .join('')
            .split(',')
            .map(email => email.trim())
            .filter(email => email.length);

        const invalidEmails = emails.filter(email => !emailIsValid(email));
        const invalidCount = invalidEmails.length;

        if (invalidCount > 0) {
            return enqueueSnackbar(
                `${i18n.common.error}: ` +
                    (invalidCount > 1
                        ? i18n.guests.multipleInvalidEmails(invalidEmails.join(', '))
                        : i18n.guests.singleInvalidEmail(invalidEmails[0])),
                {
                    variant: 'error',
                }
            );
        }

        setPendingAddition(
            pendingAddition.concat(
                compact(emails).map((inviteeEmail, idx) => ({
                    key: key + idx + 1,
                    email: inviteeEmail,
                }))
            )
        );
        setKey(key + newValue.length);
        setPendingInput('');
    }

    function removeAttendee(attendee: PendingAddition) {
        setPendingAddition(pendingAddition.filter(invitee => invitee.key !== attendee.key));
    }

    if (pending) {
        return <Spinner />;
    }

    if (!event.editable) {
        return <Navigate to={`/event/${event.id}/guest-list`} />;
    }

    return (
        <Column style={{ width: '100%' }}>
            <StickyHeader pageOffset={SIDE_SMALL_NAV_WIDTH + MEETINGS_NAV_WIDTH} style={{ justifyContent: 'flex-end' }}>
                <Button onClick={() => navigate(`/event/${event.id}/guest-list`)} style={{ marginRight: '16px' }}>
                    {i18n.button.cancel}
                </Button>
                <Button disabled={pendingAddition.length <= 0} onClick={handleAddClick}>
                    {i18n.button.add}
                </Button>
            </StickyHeader>
            <Centered>
                <PageHeadline>{i18n.guests.addPeople}</PageHeadline>
                <Copy>{i18n.guests.addPeopleByEmail}</Copy>
                <Spacer />
                <Spacer />
                <StandardInput
                    autoFocus
                    fullWidth
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPendingInput(e.target.value)}
                    onSubmit={(e: React.ChangeEvent<HTMLInputElement>) => handlePendingAdditionsSubmit(e.target.value)}
                    helperText={`${i18n.guests.emailsPlaceholder}. ${i18n.guests.addMultiplePeople}`}
                    placeholder={i18n.guests.emailsPlaceholder}
                    value={pendingInput}
                    variant="outlined"
                />
                <Spacer />
                {pendingAddition.length > 0 && (
                    <Copy $color={EColors.highlightedText} style={{ marginBottom: '16px' }}>
                        {pendingAddition.length > 1
                            ? i18n.guests.addPeopleMessage(pendingAddition.length)
                            : i18n.guests.addPersonMessage}
                    </Copy>
                )}
                {pendingAddition.map(invitee => (
                    <TopRounded
                        key={invitee.key}
                        style={{
                            marginBottom: '16px',
                        }}
                    >
                        <TruncatingSingleLineCopy maxWidth={200}>{invitee.email}</TruncatingSingleLineCopy>
                        <DeleteIcon onClick={() => removeAttendee(invitee)} />
                    </TopRounded>
                ))}
            </Centered>
        </Column>
    );
};
