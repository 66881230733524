import { Box, Input, InputProps, styled, Typography } from '@mui/material';
import React, { HTMLInputTypeAttribute } from 'react';
import { FieldError } from 'react-hook-form';
import { EColors, getColor } from 'theme';

const StyledInputField = styled(Input)<InputProps & { errror: boolean }>(({ theme: { getColor, EColors }, error }) => ({
    flex: 1,
    boxSizing: 'border-box',
    width: '100%',
    fontSize: '1rem',
    border: '1px solid',
    borderColor: error ? getColor(EColors.red) : getColor(EColors.inactiveAction),
    borderRadius: '4px',
    padding: '14.5px 10px',
    color: getColor(EColors.pureBlack),
    '& .MuiInput-input': {
        padding: 0,
    },
    '& .MuiInput-input:focus': {
        outline: 'none',
        WebkitBoxShadow: 'none',
        boxShadow: 'none',
    },
    '& .MuiInput-input:focus::placeholder': {
        color: getColor(EColors.pureBlack),
        opacity: 0.5,
    },
    '& .MuiInput-input::placeholder': {
        color: error ? getColor(EColors.red) : getColor(EColors.pureBlack),
    },
}));

type InputFieldProps = {
    label?: string;
    placeholder?: string;
    error: FieldError | undefined;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value: string | number | undefined;
    disabled?: boolean | undefined;
    required?: boolean;
    name: string;
    ref?: React.LegacyRef<HTMLInputElement> | undefined;
    type?: HTMLInputTypeAttribute;
    maxRows?: number;
    minRows?: number;
};

const TextArea = React.forwardRef<HTMLDivElement, InputFieldProps>(
    ({ label, error, type = 'text', placeholder, required = false, minRows = 1, maxRows = 3, ...field }, ref) => {
        return (
            <Box>
                {label && (
                    <Box display="flex" gap={0.5} marginBottom={1}>
                        <Typography fontWeight={500}>{label}</Typography>

                        <Typography color={getColor(EColors.pureBlack, 0.5)}>
                            {required ? '(required)' : '(optional)'}
                        </Typography>
                    </Box>
                )}
                <StyledInputField
                    type={type}
                    placeholder={placeholder ? placeholder : label}
                    error={!!error}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value}
                    disabled={field.disabled}
                    disableUnderline
                    ref={ref}
                    multiline
                    minRows={minRows}
                    maxRows={maxRows < minRows ? minRows : maxRows}
                />
                {!!error && (
                    <Typography variant="body2" color={getColor(EColors.red)} fontWeight={500}>
                        {error.message}
                    </Typography>
                )}
            </Box>
        );
    }
);

export default TextArea;
