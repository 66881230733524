import { zodResolver } from '@hookform/resolvers/zod';
import InfoIcon from '@mui/icons-material/Info';
import {
    Box,
    Button,
    DialogContent,
    FormControl,
    Grid,
    Link,
    MenuItem,
    Popover,
    Select,
    TextField,
} from '@mui/material';
import { createEvent, findPlaces, generateEventMetaData } from 'api';
import { RichTextDisplay } from 'components/Form/RichTextEditor';
import { SpinnerOverlay } from 'components/Spinner';
import { H2Headline } from 'components/ui/Headline';
import fontFns from 'fontFns';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useUser } from 'providers/user';
import { parse } from 'query-string';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { i18n } from 'translation';
import { CopyFaded, InlineRow } from 'ui';
import { tzMoment } from 'utils/moment';
import * as z from 'zod';
import { AugmentedTemplatesField, CityField, EventDatesField, EventForm, toBizlyEvent } from '../formSchema';
import { VerbiageAlert } from './styled';

export const Step1Schema = z.object({
    templateId: z.number().optional(),
    name: z.string().min(1, { message: 'Event name is required' }).max(255, { message: 'Event name is too long' }),
    location: z.string().min(1, { message: 'City location is required' }),
    eventDates: z.object({
        start: z.custom<moment.Moment>(value => moment.isMoment(value), {
            message: 'Start date must be a valid moment object',
        }),
        end: z.custom<moment.Moment>(value => moment.isMoment(value), {
            message: 'End date must be a valid moment object',
        }),
    }),
});

export const Step2Schema = z.object({
    cventId: z.string().min(1, { message: 'Cvent ID is required' }).optional(),
    costCenter: z.string().min(1, { message: 'Cost center is required' }).optional(),
    department: z.string().min(1, { message: 'Department is required' }).optional(),
    budget: z.string().optional(),
    type: z.enum(['', 'Internal', 'External']).optional(),
    recordId: z.string().min(1, { message: 'Record ID is required' }).optional(),
});

type Step1FormData = z.infer<typeof Step1Schema>;
type Step2FormData = z.infer<typeof Step2Schema>;

const MediumDialogContent = styled(DialogContent)`
    width: 20rem;
    padding: 2rem;
`;

const FormTextFieldLabel = styled(InlineRow)`
    font-size: 1em;
    line-height: 1.25em;
    font-weight: 500;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.formLabel)};
    ${fontFns.formLabel}
    margin-bottom: 0.5em;
`;

const FieldOptionalLabel = styled.span`
    font-size: 13px;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.optionalSpecifier)};
    margin-left: 5px;
`;

const AppleEventCreateStep1 = ({
    formData,
    handleCancel,
    handleContinue,
    options,
    playbookDescription,
    onViewTemplate,
}: {
    formData: Step1FormData;
    handleCancel?: () => void;
    handleContinue: (data: Step1FormData) => void;
    options: Record<string, unknown>[];
    playbookDescription?: string;
    onViewTemplate: (value: number) => void;
}) => {
    const { user } = useUser();
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<Step1FormData>({
        resolver: zodResolver(Step1Schema),
        defaultValues: formData,
    });

    useEffect(() => {
        reset(formData);
    }, [formData, reset]);

    const handleStep1Submit: SubmitHandler<Step1FormData> = data => {
        handleContinue(data);
    };

    return (
        <Box>
            <H2Headline>{i18n.meetingsPage.eventDetails}</H2Headline>
            {user.team?.authMeetingCreateFields && user.team.authMeetingCreateRedirect && (
                <VerbiageAlert severity="info" icon={<InfoIcon fontSize="inherit" />}>
                    {i18n.homepage.createMeetingModal.teamEventProtocol(user.team.name)}{' '}
                    <Link onClick={e => setAnchorEl(e.currentTarget)} style={{ cursor: 'pointer' }}>
                        {i18n.homepage.createMeetingModal.learnMore}
                    </Link>
                    <Popover
                        open={!!anchorEl}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <MediumDialogContent>
                            <RichTextDisplay value={user.team.authMeetingCreateRedirect} />
                        </MediumDialogContent>
                    </Popover>
                </VerbiageAlert>
            )}
            <form onSubmit={handleSubmit(handleStep1Submit)}>
                <Box mb={2}>
                    <Controller
                        name="templateId"
                        control={control}
                        render={({ field }) => (
                            <>
                                <FormTextFieldLabel>{i18n.homepage.createMeetingModal.playbook}</FormTextFieldLabel>
                                <AugmentedTemplatesField
                                    field="templateField"
                                    value={field.value}
                                    onChange={({ value }) => field.onChange(value)}
                                    options={options}
                                    playbookDescription={playbookDescription}
                                    onViewTemplate={onViewTemplate}
                                />
                            </>
                        )}
                    />
                </Box>

                <Box mb={2}>
                    <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                            <>
                                <FormTextFieldLabel>
                                    {i18n.homepage.createMeetingModal.eventName}
                                    <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                </FormTextFieldLabel>
                                <TextField {...field} variant="outlined" fullWidth error={!!errors.name} />
                            </>
                        )}
                    />
                </Box>

                <Box mb={2}>
                    <Controller
                        name="location"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <>
                                <FormTextFieldLabel>
                                    {i18n.homepage.createMeetingModal.location}
                                    <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                </FormTextFieldLabel>
                                <CityField
                                    field="location"
                                    onChange={({ value: cityValue }) => {
                                        onChange(cityValue.location);
                                    }}
                                    defaultValue={{ location: value || '', googlePlaceId: '' }}
                                    error={null}
                                />
                            </>
                        )}
                    />
                </Box>

                <Box mb={2}>
                    <Controller
                        name="eventDates"
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <>
                                <FormTextFieldLabel>
                                    {i18n.communication.eventDates}
                                    <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                </FormTextFieldLabel>
                                <EventDatesField
                                    value={value}
                                    onChange={val => {
                                        onChange({
                                            ...val,
                                            start: tzMoment(val.start),
                                            end: tzMoment(val.end),
                                        });
                                    }}
                                />
                            </>
                        )}
                    />
                </Box>

                <Box display="flex" justifyContent="flex-end" mt={4} gap={2}>
                    <Button variant="text" type="button" onClick={handleCancel}>
                        {i18n.button.cancel}
                    </Button>
                    <Button type="submit" variant="contained">
                        {i18n.button.continue}
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

const AppleEventCreateStep2 = ({
    formData,
    onSubmit,
    handleBack,
}: {
    formData: Step2FormData;
    onSubmit: (data: Step2FormData) => void;
    handleBack: () => void;
}) => {
    const { user } = useUser();
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm<Step2FormData>({
        resolver: zodResolver(Step2Schema),
        defaultValues: formData,
    });

    useEffect(() => {
        reset(formData);
    }, [formData, reset]);

    const handleStep2Submit: SubmitHandler<Step2FormData> = data => {
        onSubmit(data);
    };

    return (
        <Box>
            <H2Headline>{i18n.proposalForm.proposalNotes.additionalDetails}</H2Headline>
            {user.team?.authMeetingCreateFields && user.team.authMeetingCreateRedirect && (
                <VerbiageAlert severity="info" icon={<InfoIcon fontSize="inherit" />}>
                    {i18n.homepage.createMeetingModal.teamEventProtocol(user.team.name)}{' '}
                    <Link onClick={e => setAnchorEl(e.currentTarget)} style={{ cursor: 'pointer' }}>
                        {i18n.homepage.createMeetingModal.learnMore}
                    </Link>
                    <Popover
                        open={!!anchorEl}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <MediumDialogContent>
                            <RichTextDisplay value={user.team.authMeetingCreateRedirect} />
                        </MediumDialogContent>
                    </Popover>
                </VerbiageAlert>
            )}
            <form onSubmit={handleSubmit(handleStep2Submit)}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="cventId"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <FormTextFieldLabel>
                                        {i18n.homepage.createMeetingModal.meetingId}
                                        <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                    </FormTextFieldLabel>
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.cventId}
                                        disabled
                                    />
                                </>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="recordId"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <FormTextFieldLabel>
                                        {i18n.homepage.createMeetingModal.recordId}
                                        <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                    </FormTextFieldLabel>
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.recordId}
                                        disabled
                                    />
                                </>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="costCenter"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <FormTextFieldLabel>
                                        {i18n.homepage.createMeetingModal.costCenter}
                                        <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                    </FormTextFieldLabel>
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.costCenter}
                                        disabled
                                    />
                                </>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="department"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <FormTextFieldLabel>
                                        {i18n.homepage.createMeetingModal.department}
                                    </FormTextFieldLabel>
                                    <TextField {...field} variant="outlined" fullWidth error={!!errors.department} />
                                </>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="budget"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <FormTextFieldLabel>
                                        {i18n.homepage.createMeetingModal.budget}
                                        <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                    </FormTextFieldLabel>
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        fullWidth
                                        error={!!errors.budget}
                                        disabled
                                    />
                                </>
                            )}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Controller
                            name="type"
                            control={control}
                            render={({ field }) => (
                                <>
                                    <FormTextFieldLabel>
                                        {i18n.homepage.createMeetingModal.meetingType}
                                        <FieldOptionalLabel>(required)</FieldOptionalLabel>
                                    </FormTextFieldLabel>
                                    <FormControl fullWidth error={!!errors.type}>
                                        <Select {...field} defaultValue="">
                                            <MenuItem value="">
                                                <CopyFaded>{i18n.homepage.createMeetingModal.none}</CopyFaded>
                                            </MenuItem>
                                            <MenuItem value="Internal">
                                                {i18n.meetingDashboard.headerSection.settings.internal}
                                            </MenuItem>
                                            <MenuItem value="External">
                                                {i18n.meetingDashboard.headerSection.settings.external}
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </>
                            )}
                        />
                    </Grid>
                </Grid>

                <Box display="flex" justifyContent="flex-end" mt={4} gap={2}>
                    <Button variant="text" onClick={handleBack}>
                        {i18n.button.back}
                    </Button>
                    <Button type="submit" variant="contained">
                        {i18n.button.create}
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

const populateLocationData = async (
    location: string,
    setValue: React.Dispatch<React.SetStateAction<EventForm>>,
    enqueueSnackbar: ReturnType<typeof useSnackbar>['enqueueSnackbar'],
    setSubmitting: React.Dispatch<React.SetStateAction<boolean>>
) => {
    try {
        const result = await generateEventMetaData(location);
        if (!result.success || !result.eventMetadata?.location) {
            return;
        }

        const gmapsResponse = await findPlaces(result.eventMetadata.location);
        if (gmapsResponse.status === 'OK' && gmapsResponse.predictions.length > 0) {
            const locationValue: EventForm['city'] = {
                location: gmapsResponse.predictions[0].description,
                googlePlaceId: gmapsResponse.predictions[0].placeId,
            };
            setValue(prev => ({
                ...prev,
                city: locationValue,
            }));
        }
    } catch (e) {
        enqueueSnackbar(String(e), { variant: 'error' });
    } finally {
        setSubmitting(false);
    }
};

const AppleEventCreate = ({
    formData,
    setFormData,
    onCreateEvent,
    handleClose,
    options,
    playbookDescription,
    onViewTemplate,
}: {
    formData: EventForm;
    setFormData: React.Dispatch<React.SetStateAction<EventForm>>;
    onCreateEvent?: (eventId: number) => Promise<void>;
    handleClose: () => void;
    options: Record<string, unknown>[];
    playbookDescription?: string;
    onViewTemplate: (value: number) => void;
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { search } = useLocation();

    const queryParams = parse(search, { parseBooleans: true });
    const create = queryParams.create === true;

    const [submitting, setSubmitting] = useState(false);
    const [step, setStep] = useState(1);

    useEffect(() => {
        // Initialize form values from query parameters on load
        if (create) {
            setFormData(prev => {
                const updated = {
                    ...prev,
                    name: typeof queryParams.event_name === 'string' ? queryParams.event_name : prev.name,
                    budget: typeof queryParams.budget === 'string' ? queryParams.budget : prev.budget,
                    costCenter: typeof queryParams.cost_center === 'string' ? queryParams.cost_center : prev.costCenter,
                    recordId: typeof queryParams.record_id === 'string' ? queryParams.record_id : prev.recordId,
                    location: typeof queryParams.location === 'string' ? queryParams.location : prev.location,
                    cventId: typeof queryParams.internal_ref === 'string' ? queryParams.internal_ref : prev.cventId,
                    eventDates: {
                        start:
                            typeof queryParams.start === 'string'
                                ? moment(queryParams.start)
                                : prev.eventDates?.start || moment(),
                        end:
                            typeof queryParams.end === 'string'
                                ? moment(queryParams.end)
                                : prev.eventDates?.end || moment(),
                    },
                };

                return updated;
            });
        } else {
            // Ensure eventDates is always defined
            setFormData(prev => ({
                ...prev,
                eventDates: prev.eventDates || { start: tzMoment(), end: tzMoment() },
            }));
        }
    }, [create, queryParams, setFormData]);

    // Attempt to set googlePlaceId from query param location on mount if present
    useEffect(() => {
        const loc = typeof queryParams.location === 'string' ? queryParams.location.trim() : '';
        if (loc !== '') {
            (async () => {
                const gmapsResponse = await findPlaces(loc);
                if (gmapsResponse.status === 'OK' && gmapsResponse.predictions.length > 0) {
                    const googlePlaceId = gmapsResponse.predictions[0].placeId;
                    const newLocation = gmapsResponse.predictions[0].description;
                    setFormData(prev => ({
                        ...prev,
                        city: {
                            location: newLocation,
                            googlePlaceId,
                        },
                    }));
                }
            })();
        }
    }, [queryParams.location, setFormData]);

    const onSubmit = async (data: Step2FormData) => {
        setSubmitting(true);
        try {
            const { event } = await createEvent(toBizlyEvent({ ...formData, ...data }));
            if (onCreateEvent) {
                await onCreateEvent(event.id);
            }
            navigate(`/event/${event.id}/venue`);
            handleClose();
        } catch (e) {
            enqueueSnackbar(String(e), { variant: 'error' });
            setSubmitting(false);
        }
    };

    const handleContinue = async (data: Step1FormData) => {
        setFormData(prev => ({ ...prev, ...data }));

        if (!create && data.location) {
            setSubmitting(true);
            await populateLocationData(data.location, setFormData, enqueueSnackbar, setSubmitting);
        }

        setStep(2);
    };

    const handleBack = () => setStep(1);

    return (
        <>
            {step === 1 ? (
                <AppleEventCreateStep1
                    formData={formData as Step1FormData}
                    handleCancel={handleClose}
                    handleContinue={handleContinue}
                    options={options}
                    playbookDescription={playbookDescription}
                    onViewTemplate={onViewTemplate}
                />
            ) : (
                <AppleEventCreateStep2
                    formData={formData as Step2FormData}
                    onSubmit={onSubmit}
                    handleBack={handleBack}
                />
            )}
            {submitting && <SpinnerOverlay />}
        </>
    );
};

export default AppleEventCreate;
