import AddHomeOutlinedIcon from '@mui/icons-material/AddHomeOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import { Box } from '@mui/material';
import colorFns from 'colorFns';
import EstimageIconSVG from 'images/icons/estimate.svg?react';
import MegaphoneIconSVG from 'images/icons/megaphone.svg?react';
import { HOTEL_CONF, INVITE, NOTE, REGISTRATION_PAGE } from 'pages/EditParcel/utils';
import React, { useCallback, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NEW_INQUIRY_ID, useCurrentInquiry } from 'stores/current-inquiry';
import styled from 'styled-components';
import { i18n } from 'translation';
import { Column, SpacedColumn } from 'ui';
import { HEADER_HEIGHT } from 'utils/header';

export const MEETINGS_NAV_WIDTH = 182;

const ContentsWrapper = styled.div`
    position: relative;
    margin-left: ${MEETINGS_NAV_WIDTH}px;

    box-sizing: border-box;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.pureWhite)};

    display: flex;
    flex-flow: column;
    align-items: flex-start;
    width: calc(100% - ${MEETINGS_NAV_WIDTH}px);
    min-height: calc(100vh);
    padding: 0;
`;

const DashboardIcon = styled(DashboardOutlinedIcon)`
    height: 1.125rem;
    width: 1.125rem;
    color: ${colorFns.sidenavForeground};
    margin-right: 12px;
`;

const CommunicationIcon = styled(MegaphoneIconSVG)`
    fill: ${({ theme: { getColor, EColors } }) => getColor(EColors.sidenavForeground, 0.5)};
`;

const SpendTrackerIcon = styled(EstimageIconSVG)`
    fill: ${({ theme: { getColor, EColors } }) => getColor(EColors.sidenavForeground, 0.5)};
`;

const NavTitle = styled.span`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.sidenavForeground)};
    font-weight: 500;
`;

const Side = styled(SpacedColumn)`
    position: fixed;
    width: ${MEETINGS_NAV_WIDTH}px;
    height: 100%;
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.sidenavForeground)};
    box-sizing: border-box;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.subNavBackground)};
    z-index: 200;
    overflow-y: auto;
    max-height: 100vh;
`;

const NavHeader = styled.div`
    border-bottom: 1px solid ${({ theme: { getColor, EColors } }) => getColor(EColors.grey)};
    height: ${HEADER_HEIGHT}px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    box-sizing: border-box;
    position: sticky;
    top: 0;
    background-color: ${({ theme: { getColor, EColors } }) => getColor(EColors.subNavBackground)};
    z-index: 100;
`;

const NavButtons = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 16px;
`;

const SidebarLink = styled.span<{
    active?: boolean;
    hasIcon?: boolean;
    clickable?: boolean;
    isHeader?: boolean;
}>`
    color: ${({ theme: { getColor, EColors } }) => getColor(EColors.sidenavForeground, 0.5)};
    font-weight: 400;
    cursor: ${props => (props.clickable && !props.isHeader ? 'pointer' : 'default')};
    padding: 10px 0;
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;

    ${props =>
        !props.hasIcon &&
        `
        padding-left: 35px;
    `}

    &:hover {
        ${({ theme, active, clickable }) =>
            clickable && !active && `background: ${theme.getColor(theme.EColors.brand, 0.2)};`}
    }

    ${({ theme, active }) =>
        active &&
        `
        background: ${theme.getColor(theme.EColors.brand, 0.3)};
        color: ${theme.getColor(theme.EColors.sidenavForeground)};
        font-weight: 500;
    `}
`;

const IconWrapper = styled.span`
    margin-right: 10px;
    display: flex;
    align-items: center;
`;

export const OVERVIEW_PATH = '';
export const VENUE_PATH = 'venue';
export const PROPOSALS_PATH = 'venue/inquiries';
export const CONTRACTING_PATH = 'venue/contracting';
export const GUEST_LIST_PATH = 'guest-list';
export const INQUIRY_BUILDER_PATH = 'venue/inquiries/:inquiryId';
export const COMMUNICATION_PATH = 'communication';
export const SPEND_TRACKER_PATH = 'spend-tracker';

interface NavLink {
    label: string;
    path?: string;
    icon?: React.ElementType;
    isHeader?: boolean;
    subLinks?: Record<string, NavLink>;
    pathGenerator?: (inquiryId: string) => string;
}

const navLinks: Record<string, NavLink> = {
    eventDetails: {
        label: i18n.meetingsPage.sideNav.overview,
        path: OVERVIEW_PATH,
        icon: LocalActivityOutlinedIcon,
    },
    venues: {
        label: i18n.meetingsPage.sideNav.venues,
        path: VENUE_PATH,
        icon: AddHomeOutlinedIcon,
        isHeader: true,
        subLinks: {
            search: {
                label: i18n.meetingsPage.sideNav.search,
                path: `${VENUE_PATH}`,
            },
            inquiry: {
                label: i18n.meetingsPage.sideNav.inquiry,
                pathGenerator: (inquiryId: string) => `${VENUE_PATH}/inquiries/${inquiryId}`,
            },
            proposals: {
                label: i18n.meetingsPage.sideNav.proposals,
                path: PROPOSALS_PATH,
            },
        },
    },
    contracting: {
        label: i18n.meetingsPage.sideNav.contracting,
        path: CONTRACTING_PATH,
    },
    communication: {
        label: i18n.meetingsPage.sideNav.communication,
        icon: CommunicationIcon,
        isHeader: true,
        subLinks: {
            invite: {
                label: i18n.meetingsPage.sideNav.invite,
                path: `${COMMUNICATION_PATH}/${INVITE}`,
            },
            registration: {
                label: i18n.meetingsPage.sideNav.registration,
                path: `${COMMUNICATION_PATH}/${REGISTRATION_PAGE}`,
            },
            hotel: {
                label: i18n.meetingsPage.sideNav.hotel,
                path: `${COMMUNICATION_PATH}/${HOTEL_CONF}`,
            },
            note: {
                label: i18n.meetingsPage.sideNav.note,
                path: `${COMMUNICATION_PATH}/${NOTE}`,
            },
            guestlist: {
                label: i18n.meetingsPage.sideNav.guestlist,
                path: GUEST_LIST_PATH,
            },
        },
    },
    spendTracker: {
        label: i18n.meetingsPage.sideNav.spendTracker,
        path: SPEND_TRACKER_PATH,
        icon: SpendTrackerIcon,
    },
};

const NavItem = ({
    label,
    to,
    isActive,
    clickable = true,
    icon: Icon,
    isHeader,
    children,
}: {
    label: string;
    to: string;
    isActive: boolean;
    clickable?: boolean;
    icon?: React.ElementType;
    isHeader?: boolean;
    children?: React.ReactNode;
}) => {
    const content = (
        <SidebarLink active={isActive} hasIcon={!!Icon} clickable={clickable} isHeader={isHeader}>
            {Icon && (
                <IconWrapper>
                    <Icon />
                </IconWrapper>
            )}
            <span>{label}</span>
        </SidebarLink>
    );

    return (
        <>
            {clickable ? <Link to={to}>{content}</Link> : content}
            {children}
        </>
    );
};
export const MeetingsNav = ({
    eventId,
    children,
    className,
    style,
}: {
    eventId: number;
    children?: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
}) => {
    const stringId = eventId.toString();
    const { inquiry, loaded } = useCurrentInquiry();
    const location = useLocation();

    const inquiryId = useMemo(() => {
        if (loaded) {
            return String(inquiry?.id ?? NEW_INQUIRY_ID);
        }
        return '';
    }, [inquiry, loaded]);

    const isPathActive = useCallback(
        (path: string, itemKey: string) => {
            let currentPath = location.pathname.replace(`/event/${stringId}`, '');
            if (currentPath.startsWith('/')) {
                currentPath = currentPath.slice(1);
            }

            if (itemKey === 'eventDetails') return currentPath === OVERVIEW_PATH;
            if (itemKey === 'search') return currentPath === VENUE_PATH;
            if (itemKey === 'inquiry')
                return currentPath.includes(`${VENUE_PATH}/inquiries/`) && currentPath !== PROPOSALS_PATH;
            if (itemKey === 'proposals') return currentPath === PROPOSALS_PATH;

            const pattern = new RegExp(`^${path}(/|\\?)?`); // The route should be exact match or should be followed by / or ?
            return pattern.test(currentPath);
        },
        [stringId, location.pathname]
    );

    const renderNavLinks = useCallback(
        (links: Record<string, NavLink>): React.ReactNode => {
            return Object.entries(links).map(([key, link]) => {
                const linkPath = link.pathGenerator ? link.pathGenerator(inquiryId) : link.path;
                const linkUrl = linkPath !== undefined ? `/event/${stringId}/${linkPath}` : '#';
                const linkActive = linkPath !== undefined ? isPathActive(linkPath, key) : false;
                const isClickable = (!link.isHeader && !link.subLinks) || (link.isHeader && !!link.path);

                return (
                    <React.Fragment key={key}>
                        <NavItem
                            label={link.label}
                            to={linkUrl}
                            isActive={!link.isHeader && linkActive}
                            clickable={isClickable}
                            icon={link.icon}
                            isHeader={link.isHeader}
                        />
                        {link.subLinks && renderNavLinks(link.subLinks)}
                    </React.Fragment>
                );
            });
        },
        [inquiryId, stringId, isPathActive]
    );

    const memoizedNavLinks = useMemo(() => renderNavLinks(navLinks), [renderNavLinks]);

    return (
        <>
            <Side>
                <Column itemSpacing="medium">
                    <NavHeader>
                        <DashboardIcon />
                        <NavTitle>{i18n.homepage.sideNav.events}</NavTitle>
                    </NavHeader>
                    <NavButtons>
                        <Box>{memoizedNavLinks}</Box>
                    </NavButtons>
                </Column>
            </Side>

            {children && (
                <ContentsWrapper className={className} style={style}>
                    {children}
                </ContentsWrapper>
            )}
        </>
    );
};
