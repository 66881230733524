import { Box, styled, Typography } from '@mui/material';

const Container = styled(Box)(({ theme: { getColor, EColors, spacing }, isfullScreen }) => ({
    backgroundColor: getColor(EColors.pureWhite),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: spacing(2.5),
    ...(isfullScreen
        ? {
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              zIndex: 99999999,
          }
        : {
              height: '100%',
          }),
}));

const LoaderContainer = styled('div')(({ theme: { getColor, EColors }, size, color, speed }) => ({
    '--uib-size': size,
    '--uib-color': color || getColor(EColors.primaryAction),
    '--uib-speed': speed,
    '--uib-dot-size': 'calc(var(--uib-size) * 0.23)',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: 'var(--uib-size)',
    height: 'var(--uib-dot-size)',
    filter: 'url(#uib-jelly-ooze)',
}));

const Dot = styled('div')(({ delay }) => ({
    position: 'absolute',
    top: 'calc(50% - var(--uib-dot-size) / 2)',
    left: 'calc(0px - var(--uib-dot-size) / 2)',
    display: 'block',
    height: 'var(--uib-dot-size)',
    width: 'var(--uib-dot-size)',
    borderRadius: '50%',
    backgroundColor: 'var(--uib-color)',
    animation: 'stream var(--uib-speed) linear infinite both',
    transition: 'background-color 0.3s ease',
    animationDelay: delay || '0s',
    '@keyframes stream': {
        '0%, 100%': {
            transform: 'translateX(0) scale(0)',
        },
        '50%': {
            transform: 'translateX(calc(var(--uib-size) * 0.5)) scale(1)',
        },
        '99.999%': {
            transform: 'translateX(calc(var(--uib-size))) scale(0)',
        },
    },
}));

const SVGFilter = () => (
    <svg width="0" height="0">
        <defs>
            <filter id="uib-jelly-ooze">
                <feGaussianBlur in="SourceGraphic" stdDeviation="3" result="blur" />
                <feColorMatrix
                    in="blur"
                    mode="matrix"
                    values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7"
                    result="ooze"
                />
                <feBlend in="SourceGraphic" in2="ooze" />
            </filter>
        </defs>
    </svg>
);

type MirageLoaderProps = {
    size?: string;
    color?: string;
    speed?: string;
    text?: string;
    isfullScreen?: boolean;
};

const MirageLoader = ({ size = '60px', color, speed = '2.6s', text = '', isfullScreen = false }: MirageLoaderProps) => (
    <Container isfullScreen={isfullScreen}>
        <LoaderContainer color={color} size={size} speed={speed}>
            {[0, 0.2, 0.4, 0.6, 0.8].map((delay, index) => (
                <Dot key={index} delay={`calc(var(--uib-speed) * -${delay})`} />
            ))}
        </LoaderContainer>
        {text && (
            <Typography fontSize="18px" fontWeight={600}>
                {text}
            </Typography>
        )}
        <SVGFilter />
    </Container>
);

export default MirageLoader;
