import { withSnackbar } from 'notistack';
import { Component } from 'react';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';

import { addAttendees } from 'api';
import colorFns from 'colorFns';
import FileUploader from 'components/FileUploader';
import { MaterialTable } from 'components/MaterialTable';
import { MEETINGS_NAV_WIDTH } from 'components/MeetingsNav';
import { SIDE_SMALL_NAV_WIDTH } from 'components/SideNav';
import { StickyHeader, stickyHeaderHeight } from 'components/StickyHeader';
import Button from 'components/ui/Button';
import { Headline, PageHeadline } from 'components/ui/Headline';
import fontFns from 'fontFns';
import { withEventContext } from 'providers/event';
import { withUserContext } from 'providers/user';
import withRouter from 'providers/withRouter';
import { contentWidth } from 'shared';
import { i18n } from 'translation';
import { Column, Copy, SmallerCopy, Spacer } from 'ui';

const Centered = styled(Column)`
    width: ${contentWidth};
    margin: ${stickyHeaderHeight} auto;
    padding: 36px 0;
`;

const FormHeading = styled(Headline)`
    color: ${colorFns.formHeading};
    ${fontFns.formHeading}
`;

const defaultOption = { id: '', label: '' };

class AddAttendeesImportClass extends Component {
    state = {
        fileName: '',
        uploadedFileData: null,
        loadingFile: false,

        fieldOptions: [
            { id: 'email', label: i18n.authFields.email, selectedIdx: null },
            { id: 'firstName', label: i18n.authFields.firstName, selectedIdx: null },
            { id: 'lastName', label: i18n.authFields.lastName, selectedIdx: null },
            { id: 'phone', label: i18n.authFields.phoneNumber, selectedIdx: null },
            {
                id: 'external_id',
                label: this.props.userContext.user.team?.externalIdFieldLabel,
                selectedIdx: null,
            },
            {
                id: 'hotel_confirmation_number',
                label: i18n.common.hotelConfirmationNumber,
                selectedIdx: null,
            },
        ],
        numCols: 0,

        pending: false,
    };

    getPendingAdditions = () => {
        const appliedHeaders = this.state.fieldOptions.filter(o => o.selectedIdx !== null);
        return this.getSelectedRows().map(({ data }) => {
            return appliedHeaders.reduce(
                (response, { id, selectedIdx }) => ({
                    ...response,
                    [id]: data[selectedIdx],
                }),
                {}
            );
        });
    };

    async handleAddClick() {
        if (this.props.onAdd) {
            return this.props.onAdd(this.getPendingAdditions());
        }

        const { enqueueSnackbar, navigate, setAttendees } = this.props;
        const { event } = this.props.eventContext;

        const pendingAdditions = this.getPendingAdditions();

        const successUrl = `/event/${event.id}/guest-list`;
        this.setState({
            pending: true,
        });
        try {
            const updatedAttendees = await addAttendees(event.id, pendingAdditions);

            setAttendees(updatedAttendees);

            enqueueSnackbar(i18n.guests.attendeesAdded(pendingAdditions.length), {
                variant: 'info',
            });
            navigate(successUrl);
        } catch (e) {
            enqueueSnackbar(`${i18n.common.error}: ${e}`, { variant: 'error' });
            this.setState({
                pending: false,
            });
        }
    }

    handleFile = file => {
        const reader = new FileReader();
        const hasReadAsBinaryString = reader.readAsBinaryString;

        this.setState({ loadingFile: true });
        reader.onload = e => this.readerOnLoad(e.target.result, hasReadAsBinaryString, file);

        if (hasReadAsBinaryString) {
            reader.readAsBinaryString(file);
        } else {
            reader.readAsArrayBuffer(file);
        }
    };

    readerOnLoad = async (result, isBinary, file) => {
        const XLSX = await import('xlsx');
        const fileBinary = isBinary ? result : arrayBufferToBinary(result);

        const wb = XLSX.read(fileBinary, { type: 'binary' });
        const rowData = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]], {
            header: 1,
        });

        // TODO: files too large seem to have rowData empty
        if (rowData.length === 0) {
            return this.setState({ loadingFile: false });
        }

        const hasHeaderRow = this.hasHeaderRow(rowData);
        const [headerRow, ...restRowData] = rowData;

        const numCols = Math.max(...(hasHeaderRow ? restRowData : rowData).map(r => r.length));
        const tableRowData = (hasHeaderRow ? restRowData : rowData).map((row, idx) => ({
            id: idx,
            data: row,
            isSelected: true,
        }));

        this.setState(prevState => {
            // parse header row for options
            let nextOptions;
            if (hasHeaderRow) {
                const optionsDict = prevState.fieldOptions.reduce(
                    (dict, cur) => ({
                        ...dict,
                        [cur.label.toLowerCase()]: { ...cur },
                    }),
                    {}
                );
                headerRow.forEach((header, idx) => {
                    if (optionsDict[header.toLowerCase()]) {
                        optionsDict[header.toLowerCase()].selectedIdx = idx;
                    }
                });

                nextOptions = Object.keys(optionsDict).map(key => optionsDict[key]);
            }

            return {
                loadingFile: false,
                uploadedFileData: tableRowData,
                ...(nextOptions ? { fieldOptions: nextOptions } : {}),
                numCols,
                fileName: file.name,
            };
        });
    };

    removeFile = () => {
        this.setState(prevState => ({
            fileName: '',
            uploadedFileData: null,
            fieldOptions: prevState.fieldOptions.map(o => ({
                ...o,
                selectedIdx: null,
            })),
        }));
    };

    hasHeaderRow = fileData => {
        const headerSet = new Set(this.state.fieldOptions.map(o => o.label.toLowerCase()));
        return fileData[0].some(cell => headerSet.has(('' + cell).toLowerCase()));
    };

    getHeaders = () => {
        const headers = new Array(this.state.numCols).fill(defaultOption).map((o, idx) => ({ ...o, id: idx }));
        this.state.fieldOptions.forEach(option => {
            if (option.selectedIdx !== null) {
                headers[option.selectedIdx] = option;
            }
        });
        return headers;
    };

    handleHeaderChange = (selectedIdx, newValue) => {
        this.setState(prevState => ({
            fieldOptions: prevState.fieldOptions.map(o => {
                // clear
                if (o.selectedIdx === selectedIdx && o.id !== newValue) {
                    return { ...o, selectedIdx: null };
                }

                // set
                return {
                    ...o,
                    selectedIdx: o.id === newValue && newValue !== defaultOption.id ? selectedIdx : o.selectedIdx,
                };
            }),
        }));
    };

    selectRow = rowIdx => {
        this.setState(prevState => ({
            uploadedFileData: prevState.uploadedFileData.map((r, i) =>
                i === rowIdx ? { ...r, isSelected: !r.isSelected } : r
            ),
        }));
    };

    getSelectedRows = (state = this.state) => state.uploadedFileData.filter(r => r.isSelected);

    selectAllRows = () => {
        this.setState(prevState => {
            return {
                uploadedFileData: prevState.uploadedFileData.map(r => ({
                    ...r,
                    isSelected: this.getSelectedRows(prevState).length ? false : true,
                })),
            };
        });
    };

    renderFileUploader() {
        const { fileName, loadingFile } = this.state;
        return (
            <>
                <FormHeading
                    style={{
                        whiteSpace: 'inherit',
                        textTransform: 'unset',
                    }}
                >
                    {i18n.guests.uploadCSVOrXLS}
                </FormHeading>
                <SmallerCopy>{i18n.guests.fieldRequirements}:</SmallerCopy>
                <SmallerCopy>
                    {i18n.guests.uploadFields.email} ({i18n.guests.uploadFields.required}),{' '}
                    {i18n.guests.uploadFields.firstName}, {i18n.guests.uploadFields.lastName},{' '}
                    {i18n.guests.uploadFields.phone}, {i18n.guests.uploadFields.id},{' '}
                    {i18n.guests.uploadFields.hotelConfirmationNumber}
                </SmallerCopy>
                <Spacer />
                <FileUploader
                    ctaLabel={i18n.guests.selectFile}
                    description={i18n.guests.needHelp}
                    onSubmit={this.handleFile}
                    prompt={fileName ? fileName : i18n.guests.uploadPrompt}
                    fileName={fileName}
                    accept=".csv,.xls,.xlsx"
                    onRemove={this.removeFile}
                    disabled={loadingFile}
                />
            </>
        );
    }

    renderTable() {
        const { fieldOptions, uploadedFileData } = this.state;
        return (
            <>
                <FormHeading
                    style={{
                        whiteSpace: 'inherit',
                        textTransform: 'unset',
                    }}
                >
                    {i18n.guests.matchColumns}
                </FormHeading>
                <SmallerCopy>{i18n.guests.matchColumnDescription}</SmallerCopy>
                <SmallerCopy>{i18n.guests.matchFieldRequirements}</SmallerCopy>
                <Spacer />

                <MaterialTable
                    dynamicHeaders
                    headers={this.getHeaders()}
                    defaultHeader={defaultOption}
                    availableHeaders={fieldOptions}
                    onHeaderChange={this.handleHeaderChange}
                    rows={uploadedFileData}
                    onSelect={this.selectRow}
                    onSelectAll={this.selectAllRows}
                />
            </>
        );
    }

    handleClose() {
        if (this.props.onClose) {
            return this.props.onClose();
        }

        window.history.back();
    }

    renderButtonsHeader() {
        const { uploadedFileData, fieldOptions } = this.state;
        return (
            <StickyHeader pageOffset={SIDE_SMALL_NAV_WIDTH + MEETINGS_NAV_WIDTH} style={{ justifyContent: 'flex-end' }}>
                <Button onClick={() => this.handleClose()} variant="outlined" style={{ marginRight: '16px' }}>
                    {i18n.button.cancel}
                </Button>
                <Button
                    disabled={
                        !uploadedFileData ||
                        fieldOptions.find(o => o.id === 'email').selectedIdx === null ||
                        this.getSelectedRows().length <= 0
                    }
                    onClick={() => this.handleAddClick()}
                >
                    {i18n.button.import}
                </Button>
            </StickyHeader>
        );
    }

    render() {
        const { uploadedFileData } = this.state;

        const { event } = this.props.eventContext;

        if (!event.editable && !this.props.isNested) {
            return <Navigate to={`/event/${event.id}/guest-list`} />;
        }

        return (
            <Column style={{ width: '100%' }}>
                {this.renderButtonsHeader()}
                <Centered>
                    <PageHeadline>{i18n.guests.addPeople}</PageHeadline>
                    <Copy>{i18n.guests.addPeopleHelper}</Copy>
                    <Spacer />
                    <Spacer />

                    {this.renderFileUploader()}

                    <Spacer />

                    {uploadedFileData && this.renderTable()}
                </Centered>
            </Column>
        );
    }
}

function arrayBufferToBinary(data) {
    const bytes = new Uint8Array(data);
    const length = bytes.byteLength;
    let binary = '';
    for (let i = 0; i < length; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return binary;
}

export const AddAttendeesImport = withSnackbar(withRouter(withEventContext(withUserContext(AddAttendeesImportClass))));
