import {
    alpha,
    Chip,
    Paper,
    styled,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { EVED_INVOICE_LINK } from 'constants/evedInvoice';
import { Link } from 'react-router-dom';
import { EColors, getColor } from 'theme';
import { formatDateToShort } from '../util';

const StyledTable = styled(Table)({
    border: '1px solid #DBDBDB',
});

const StyledTableCellHeader = styled(TableCell)({
    fontSize: '0.875rem',
    fontWeight: 500,
});

const StyledTableHead = styled(TableHead)({
    backgroundColor: getColor(EColors.softAccentedBackground),
});

const StyledTableCellBody = styled(TableCell)({
    fontSize: '0.875rem',
    fontWeight: 400,
});

const StyledLink = styled(Link)({
    textDecoration: 'none',
    color: getColor(EColors.primaryAction),
    fontWeight: 500,
});

const StatusChip = styled(Chip)<{ backgroundColor: string }>(({ backgroundColor }) => ({
    backgroundColor: backgroundColor,
    color: 'black',
    borderRadius: '4px',
}));

type InvoiceTableProps = {
    invoices: Bizly.EvedInvoice[];
};

const renderStatus = (status: Bizly.EvedInvoiceStatus) => {
    let statusColor = alpha(getColor(EColors.invoicePaid), 0.2);

    if (status !== 'Paid') {
        statusColor = getColor(EColors.bizlyOSWarning);
    }

    return <StatusChip label={status} backgroundColor={statusColor} />;
};

const formatInvoiceAmount = (amount: Nullable<number> = 0, currencyCode = 'USD'): string => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    }).format(amount ?? 0);
};

const InvoiceTable = (props: InvoiceTableProps) => {
    const { invoices } = props;

    const INVOICE_COLUMNS = [
        {
            id: 'vendorName',
            label: 'Vendor Name',
            render: (value: Bizly.EvedInvoice) => (
                <Typography fontSize="0.875rem" fontWeight={400}>
                    {value.Supplier?.CompanyName}
                </Typography>
            ),
        },
        {
            id: 'invoiceNumber',
            label: 'Invoice Number',
            render: (value: Bizly.EvedInvoice) => <Typography fontSize="0.875rem">{value.InvoiceNumber}</Typography>,
        },
        {
            id: 'invoiceDate',
            label: 'Invoice Date',
            render: (value: Bizly.EvedInvoice) => formatDateToShort(value.InvoiceDate),
        },
        {
            id: 'invoiceAmount',
            label: 'Invoice Amount',
            render: (value: Bizly.EvedInvoice) => formatInvoiceAmount(value.TotalAmount, value.Currency),
        },
        {
            id: 'balance',
            label: 'Balance',
            render: (value: Bizly.EvedInvoice) => formatInvoiceAmount(value.Balance, value.Currency),
        },
        {
            id: 'status',
            label: 'Status',
            render: (value: Bizly.EvedInvoice) => renderStatus(value.PaymentStatus),
        },
        {
            id: 'action',
            label: 'Action',
            render: (invoice: Bizly.EvedInvoice) => (
                <StyledLink target="_blank" to={EVED_INVOICE_LINK.replace('{{id}}', invoice.Id)}>
                    View
                </StyledLink>
            ),
        },
    ];

    return (
        <TableContainer component={Paper}>
            <StyledTable>
                <StyledTableHead>
                    <TableRow>
                        {INVOICE_COLUMNS.map(column => (
                            <StyledTableCellHeader key={column.id}>{column.label}</StyledTableCellHeader>
                        ))}
                    </TableRow>
                </StyledTableHead>
                <TableBody>
                    {invoices.map(invoice => (
                        <TableRow key={invoice.VendorId}>
                            {INVOICE_COLUMNS.map(column => (
                                <StyledTableCellBody key={column.id}>{column.render(invoice)}</StyledTableCellBody>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            </StyledTable>
        </TableContainer>
    );
};

export default InvoiceTable;
