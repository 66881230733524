export const GET_EVENTS = 'GET_EVENTS';
export const SEARCH_MEETINGS = 'SEARCH_MEETINGS';
export const GET_CURRENT_INQUIRY = 'GET_CURRENT_INQUIRY';
export const GET_EVENT = 'GET_EVENT';
export const GET_VENUE_INQUIRIES = 'GET_VENUE_INQUIRIES';
export const GET_TEAM_MEMBERS = 'GET_TEAM_MEMBERS';
export const VENUE_TOKEN = 'VENUE_TOKEN';
export const VENUE_CONTENT = 'VENUE_CONTENT';
export const GET_EVENT_RESOURCES = 'GET_EVENT_RESOURCES';
export const GET_BOOKINGS_FOR_EVENT = 'GET_BOOKINGS_FOR_EVENT';
